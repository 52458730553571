import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import { BasicModal, Container, } from "@truenorthmortgage/olympus";
import "./Modals.scss";
const KratosModal = ({ is_open = false, callback = null, children, is_confirmation_modal = false, }) => {
    const modalRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        var _a, _b;
        if (is_open === true) {
            (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.open();
            if (!isModalOpen) {
                setIsModalOpen(true);
            }
        }
        else {
            (_b = modalRef.current) === null || _b === void 0 ? void 0 : _b.close();
            setIsModalOpen(false);
        }
    }, [is_open]);
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ style: { position: "absolute" } }, { children: _jsx(Container, Object.assign({ className: "general-modal-container " +
                    (is_confirmation_modal === true ? "confirmation-modal" : "") }, { children: _jsx(BasicModal, Object.assign({ ref: modalRef, title: "Generic Modal", onClose: callback, className: "general-modal" }, { children: children })) })) })) }));
};
export default KratosModal;

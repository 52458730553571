import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Layout from "../../Shared/Layout";
import { Container, ActionHeader, Column, SelectComponent, MoneyComponent, Form, } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { router, usePage } from "@inertiajs/react";
import { useState } from "react";
import StatusTag from "../../Shared/StatusTag";
import "./investmentAccount.scss";
const EditInvestmentAccount = () => {
    var _a, _b, _c, _d, _e, _f;
    const { investment_account, types, errors } = usePage().props;
    const [type, setType] = useState(investment_account === null || investment_account === void 0 ? void 0 : investment_account.type);
    const [startingBalance, setStartingBalance] = useState((_b = (_a = investment_account === null || investment_account === void 0 ? void 0 : investment_account.accountingAccount) === null || _a === void 0 ? void 0 : _a.balance) === null || _b === void 0 ? void 0 : _b.toString());
    const typeCallback = (type_value) => {
        setType(type_value);
    };
    const startingBalanceCallback = (starting_balance_value) => {
        setStartingBalance(starting_balance_value);
    };
    const handleSubmit = () => {
        var _a;
        router.patch("/investors/investment-account/update", {
            investment_account_uuid: investment_account === null || investment_account === void 0 ? void 0 : investment_account.uuid,
            investor_account_uuid: (_a = investment_account === null || investment_account === void 0 ? void 0 : investment_account.investorAccount) === null || _a === void 0 ? void 0 : _a.uuid,
            type: type,
            starting_balance: startingBalance,
        });
    };
    return (_jsxs(Container, Object.assign({ className: "investment-account-landing-header" }, { children: [_jsxs(Column, Object.assign({ columnStyle: "full no-padding-bottom align-center" }, { children: [_jsx(ActionHeader, { title: "Investment Account" }), _jsx(StatusTag, { status: investment_account === null || investment_account === void 0 ? void 0 : investment_account.status, subtitle: investment_account === null || investment_account === void 0 ? void 0 : investment_account.friendly_id })] }), "investment-account-status-column"), _jsx(Form, Object.assign({ className: "investment-account-form" }, { children: _jsxs(Column, Object.assign({ columnStyle: "full" }, { children: [_jsxs("div", Object.assign({ className: "column" }, { children: [_jsx("h3", Object.assign({ className: "bold", style: { marginLeft: -5, marginTop: 15 } }, { children: "Investor Details" })), _jsxs("div", Object.assign({ className: "columns" }, { children: [_jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Investor" }), _jsx("div", { children: (_c = investment_account === null || investment_account === void 0 ? void 0 : investment_account.investorAccount) === null || _c === void 0 ? void 0 : _c.name })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Investor Account Type" }), _jsx("div", Object.assign({ className: "capitalize" }, { children: (_d = investment_account === null || investment_account === void 0 ? void 0 : investment_account.investorAccount) === null || _d === void 0 ? void 0 : _d.type }))] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Investor Friendly ID" }), _jsx("div", { children: _jsx("a", Object.assign({ style: { fontWeight: "bold" }, href: "/investors/view/" +
                                                            ((_e = investment_account === null || investment_account === void 0 ? void 0 : investment_account.investorAccount) === null || _e === void 0 ? void 0 : _e.uuid) }, { children: (_f = investment_account === null || investment_account === void 0 ? void 0 : investment_account.investorAccount) === null || _f === void 0 ? void 0 : _f.friendly_id })) })] }))] }))] })), _jsxs("div", Object.assign({ className: "column" }, { children: [_jsx("h3", Object.assign({ className: "bold", style: { marginLeft: -5, marginTop: 10 } }, { children: "Account Details" })), _jsxs("div", Object.assign({ className: "columns" }, { children: [_jsxs(SelectComponent, Object.assign({ id: "type", label: "Account Type", columnStyle: "side-by-side-element column col-12-12", error: errors.type, onChange: typeCallback, disabled: true, value: type, className: "select-capitalize" }, { children: [_jsx("option", Object.assign({ disabled: true, value: "" }, { children: "Account Type" })), types === null || types === void 0 ? void 0 : types.map((type) => (_jsx("option", Object.assign({ id: type.value, value: type.value }, { children: type.label }), type.value)))] }), "type"), _jsx("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: _jsx(MoneyComponent, { id: "starting_balance", label: "Starting Balance", columnStyle: "side-by-side-element column col-12-12", error: errors === null || errors === void 0 ? void 0 : errors.starting_balance, value: startingBalance, onChange: startingBalanceCallback, disabled: true }, "starting_balance") }))] }))] })), _jsxs("button", Object.assign({ type: "button", className: "button submit-button", onClick: handleSubmit, disabled: true }, { children: [_jsx(FontAwesomeIcon, { icon: "save" }), "Save"] }))] }), "investor-details-column") }), "investment-account-form")] })));
};
EditInvestmentAccount.layout = (page) => (_jsx(Layout, Object.assign({ title: "New Investment Account" }, { children: page })));
export default EditInvestmentAccount;

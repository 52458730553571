import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { usePage, router } from "@inertiajs/react";
import Layout from "../../Shared/Layout";
import { ActionHeader, ActionDropdown, ActionOption, Column, Container, } from "@truenorthmortgage/olympus";
import InvestmentListingTable from "../../Shared/InvestmentListingTable/InvestmentListingTable";
import { unitsDividedByMultiplier, formatNumber } from "../../../utils/utils";
import "./ownershipCertificate.scss";
import StatusTag from "../../Shared/StatusTag";
const ViewOwnershipCertificate = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { ownership_certificate } = usePage().props;
    const createInvestmentListing = () => {
        var _a;
        router.get("/investment-listings/draft/" + ((_a = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment) === null || _a === void 0 ? void 0 : _a.uuid));
    };
    return ownership_certificate ? (_jsx(_Fragment, { children: _jsx(Container, Object.assign({ className: "ownership-certificate-landing-header" }, { children: _jsxs(Column, Object.assign({ columnStyle: "full bottom-padding align-center" }, { children: [_jsx(ActionHeader, Object.assign({ title: ((_a = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment) === null || _a === void 0 ? void 0 : _a.type_label) +
                            " " +
                            (ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment_type_label) +
                            " " +
                            (ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.holder_type_label) +
                            " Certificate", widgetClassName: "no-margin" }, { children: _jsx(ActionDropdown, Object.assign({ id: "view-ownership-certificate-actions", inputText: "Actions", disabled: (ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.holder_type) !== "issuer" ||
                                (ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.valid_to) !== null ||
                                ((_b = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment) === null || _b === void 0 ? void 0 : _b.max_units_available) ==
                                    undefined ||
                                ((_c = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment) === null || _c === void 0 ? void 0 : _c.max_units_available) <= 0 }, { children: [
                                _jsx(ActionOption, Object.assign({ clickCallback: createInvestmentListing }, { children: "Create Investment Listing" }), "create"),
                            ].filter((option) => option) })) })), _jsx(StatusTag, { status: (ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.valid_to) === null ? "active" : "expired", subtitle: ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.uuid }), _jsx("div", Object.assign({ className: "column col-6-12 no-view-padding" }, { children: _jsxs("div", Object.assign({ className: "columns" }, { children: [_jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Valid From" }), _jsx("div", Object.assign({ className: "capitalize" }, { children: ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.valid_from }))] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Valid To" }), _jsx("div", { children: (_d = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.valid_to) !== null && _d !== void 0 ? _d : "(not set)" })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Certificate Holder Type" }), _jsx("div", { children: ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.holder_type_label })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Held By" }), _jsx("div", { children: _jsx("a", Object.assign({ title: "View Owner", href: "/investors/view/" +
                                                    (ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investor_account_uuid) }, { children: ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.owner })) })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Units" }), _jsx("div", { children: formatNumber(unitsDividedByMultiplier(ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.units, (_e = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment) === null || _e === void 0 ? void 0 : _e.units_multiplier)) })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Price Per Unit" }), _jsxs("div", { children: ["$", formatNumber(unitsDividedByMultiplier(ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.price_per_unit, (_f = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment) === null || _f === void 0 ? void 0 : _f.units_multiplier))] })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Certificate Investment Type" }), _jsx("div", { children: ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment_type_label })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Investment" }), _jsx("div", { children: _jsx("a", Object.assign({ title: "View Investment", href: "/investments/view/" +
                                                    ((_g = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment) === null || _g === void 0 ? void 0 : _g.uuid) }, { children: ((_h = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment) === null || _h === void 0 ? void 0 : _h.type_label) +
                                                    " " +
                                                    ((_j = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment) === null || _j === void 0 ? void 0 : _j.friendly_id) })) })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Rate Modifier" }), _jsx("div", { children: ownership_certificate.holder_type === "investor"
                                                ? (ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.rate_modifier) + "%"
                                                : "N/A" })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Purchased From Purchase Order(s)" }), _jsxs("div", { children: [ownership_certificate.holder_type === "investor" && (_jsxs(_Fragment, { children: [(ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.purchase_orders) !== undefined &&
                                                            ((_k = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.purchase_orders) === null || _k === void 0 ? void 0 : _k.length) > 0 &&
                                                            (ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.purchase_orders.map((purchase_order) => (_jsx("div", Object.assign({ style: { marginBottom: 4 } }, { children: _jsx("a", Object.assign({ title: "View Purchase Order", id: "view-purchase-order-" + purchase_order.uuid, href: "/investments/purchase-order/view/" +
                                                                        purchase_order.uuid }, { children: purchase_order.uuid }), purchase_order.uuid + "-link") }), purchase_order.uuid + "-div")))), ((ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.purchase_orders) === undefined ||
                                                            ((_l = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.purchase_orders) === null || _l === void 0 ? void 0 : _l.length) === 0) &&
                                                            "None"] })), ownership_certificate.holder_type !== "investor" && "N/A"] })] }))] })) })), ((_m = ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment_listings) === null || _m === void 0 ? void 0 : _m.length) >= 1 && (_jsxs(_Fragment, { children: [_jsx(ActionHeader, { title: "Investment Listings", className: "no-left-right-padding", widgetClassName: "no-view-padding no-bottom-margin" }), _jsx(InvestmentListingTable, { investment_listings: ownership_certificate === null || ownership_certificate === void 0 ? void 0 : ownership_certificate.investment_listings })] }))] }), "view-ownership-certificate-column") }), "ownership-certificate-landing-header") })) : null;
};
ViewOwnershipCertificate.layout = (page) => (_jsx(Layout, Object.assign({ title: "View Ownership Certificate" }, { children: page })));
export default ViewOwnershipCertificate;

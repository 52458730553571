import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { router, usePage } from "@inertiajs/react";
import Layout from "../../Shared/Layout";
import { ActionButton, ActionButtonsCell, BasicRow, CellHeader, Table, TextCell, Container, SearchComponent, RawCell, SelectComponent, } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KratosPagination from "../../Shared/KratosPagination";
import { getParamFromUrlString } from "../../../utils/utils";
import "./Users.scss";
const Users = (attributes) => {
    var _a, _b, _c;
    const url = usePage().url;
    const { CONSTANTS, usersData, status_option } = usePage().props;
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState({
        search_users: getParamFromUrlString(url, "search_users"),
        status: (_a = getParamFromUrlString(url, "status")) !== null && _a !== void 0 ? _a : "active",
    });
    const deleteUser = (user) => {
        if (window.confirm("Do you really want to delete this user?")) {
            router.delete("/users/" + user.uuid, {
                onStart: () => setLoading(true),
                onFinish: () => setLoading(false),
            });
        }
    };
    const activateUser = (user) => {
        if (window.confirm("Do you really want to activate this user?")) {
            router.patch("/users/status/" + user.uuid, { status: "active" }, {
                onStart: () => setLoading(true),
                onFinish: () => setLoading(false),
            });
        }
    };
    const sendResetPasswordUserEmail = (user) => {
        if (window.confirm("Do you really want to send the password reset email to this user?")) {
            router.patch("/users/send-reset-password-email/" + user.uuid, { action: "reset_email" }, {
                onStart: () => setLoading(true),
                onFinish: () => setLoading(false),
            });
        }
    };
    const sendActivationUserEmail = (user) => {
        if (window.confirm("Do you really want to send the activation email to this user?")) {
            router.patch("/users/send-activation-email/" + user.uuid, { action: "activation_email" }, {
                onStart: () => setLoading(true),
                onFinish: () => setLoading(false),
            });
        }
    };
    const handleSearchTerm = (term) => {
        const search = searchParam;
        if (term !== search.search_users) {
            searchParam.search_users = term;
            setSearchParam(search);
        }
    };
    const handleSearchStatus = (status_value) => {
        const search = searchParam;
        if (status_value !== search.status) {
            searchParam.status = status_value;
            setSearchParam(search);
            handleSearch();
        }
    };
    const handleSearch = () => {
        var _a, _b;
        const search = searchParam;
        router.get("?search_users=" +
            ((_a = search.search_users) !== null && _a !== void 0 ? _a : "") +
            "&status=" +
            ((_b = search.status) !== null && _b !== void 0 ? _b : ""));
    };
    const userActionButtons = (user) => {
        const actions = [];
        actions.push(_jsx(ActionButton, Object.assign({ id: "view-" + user.uuid, title: "View", disabled: loading, callback: () => router.get("/users/view/" + user.uuid) }, { children: _jsx(FontAwesomeIcon, { icon: "eye" }) }), "action-view"));
        if (CONSTANTS.USER.TYPE.PORTAL.value === attributes.type &&
            true === (user === null || user === void 0 ? void 0 : user.hasPassword)) {
            actions.push(_jsx(ActionButton, Object.assign({ id: "activate-" + user.uuid, title: "Send Portal Password Reset Email", disabled: loading, callback: () => sendResetPasswordUserEmail(user) }, { children: _jsx(FontAwesomeIcon, { icon: "unlock" }) }), "action-reset-password-email"));
        }
        if (CONSTANTS.USER.TYPE.PORTAL.value === attributes.type &&
            false === (user === null || user === void 0 ? void 0 : user.hasPassword)) {
            actions.push(_jsx(ActionButton, Object.assign({ id: "activate-" + user.uuid, title: "Send Portal Activation Email", disabled: loading, callback: () => sendActivationUserEmail(user) }, { children: (user === null || user === void 0 ? void 0 : user.account_activation_token) !== null ? (_jsx("i", { className: "fa-duotone fa-envelope-circle-check" })) : (_jsx("i", { className: "fa-solid fa-envelope-circle-check" })) }), "action-activation-password-email"));
        }
        actions.push(_jsx(ActionButton, Object.assign({ id: "edit-" + user.uuid, title: "Edit", disabled: loading, callback: () => router.get("/users/edit/" + user.uuid) }, { children: _jsx(FontAwesomeIcon, { icon: "pencil" }) }), "action-edit"));
        if (user.status === "active") {
            actions.push(_jsx(ActionButton, Object.assign({ id: "delete-" + user.uuid, title: "Delete", className: "red", disabled: loading, callback: () => deleteUser(user) }, { children: _jsx(FontAwesomeIcon, { icon: "trash" }) }), "action-delete"));
        }
        if (user.status === "inactive") {
            actions.push(_jsx(ActionButton, Object.assign({ id: "activate-" + user.uuid, title: "Activate", disabled: loading, callback: () => activateUser(user) }, { children: _jsx(FontAwesomeIcon, { icon: "thumbs-up" }) }), "action-activate"));
        }
        return actions;
    };
    return (_jsxs(Container, Object.assign({ className: "users-landing-header" }, { children: [_jsxs("div", Object.assign({ className: "search-wrap" }, { children: [_jsx(SearchComponent, { fieldName: "users", placeholder: "Search Term", allowRouteChanges: false, valueChanged: handleSearchTerm, defaultValue: (_b = searchParam.search_users) !== null && _b !== void 0 ? _b : "", onEnterPress: handleSearch }), _jsx("button", Object.assign({ type: "button", className: "button submit-search-button", onClick: handleSearch }, { children: "Search" })), _jsx(SelectComponent, Object.assign({ id: "preferred-search", onChange: handleSearchStatus, value: (_c = searchParam.status) !== null && _c !== void 0 ? _c : CONSTANTS.USER.STATUS.ACTIVE.value, columnStyle: "search-select" }, { children: status_option === null || status_option === void 0 ? void 0 : status_option.map((status) => (_jsx("option", Object.assign({ id: status.value, value: status.value }, { children: status.label }), status.value))) }))] })), _jsxs(Table, Object.assign({ showSearchBar: false, title: "", emptyText: "Users not found.", isEmpty: (usersData === null || usersData === void 0 ? void 0 : usersData.length) === 0 }, { children: [_jsxs(CellHeader, Object.assign({ className: "users-header section-header" }, { children: [_jsx(RawCell, { children: "First Name" }), _jsx(RawCell, { children: "Last Name" }), _jsx(RawCell, { children: "Email" }), _jsx(RawCell, { children: "Title" }), _jsx(RawCell, { children: "Time Zone" }), _jsx(RawCell, { children: "Status" }), _jsx(TextCell, {})] })), usersData &&
                        (usersData === null || usersData === void 0 ? void 0 : usersData.map((user) => {
                            var _a, _b, _c, _d;
                            return (_jsxs(BasicRow, { children: [_jsx(TextCell, { children: (_a = user.firstname) !== null && _a !== void 0 ? _a : "API Token" }), _jsx(TextCell, { children: (_b = user.lastname) !== null && _b !== void 0 ? _b : "-" }), _jsx(TextCell, { children: (_c = user.email) !== null && _c !== void 0 ? _c : "-" }), _jsx(TextCell, { children: user.title }), _jsx(TextCell, { children: (_d = user.timezone) !== null && _d !== void 0 ? _d : "-" }), _jsx(TextCell, Object.assign({ className: "text-uppercase" }, { children: user.status })), _jsx(ActionButtonsCell, Object.assign({ className: "nowrap" }, { children: userActionButtons(user) }))] }, user.uuid));
                        }))] })), _jsx("div", Object.assign({ className: "pagination-wrap" }, { children: (usersData === null || usersData === void 0 ? void 0 : usersData.length) > 0 && _jsx(KratosPagination, {}) }))] })));
};
Users.layout = (page) => (_jsx(Layout, Object.assign({ title: "Investor Portal Users" }, { children: page })));
export default Users;

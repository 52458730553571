import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { usePage, router } from "@inertiajs/react";
import Layout from "../Shared/Layout";
import { BasicRow, Table, TextCell, CellHeader, Container, RawCell, SearchComponent, } from "@truenorthmortgage/olympus";
import { getParamFromUrlString } from "../../utils/utils";
const Dashboard = () => {
    const url = usePage().url;
    const { users } = usePage().props;
    const [searchParam, setSearchParam] = useState(getParamFromUrlString(url, "search_users"));
    const handleSearch = () => {
        router.get("/dashboard" + (searchParam ? "?search_users=" + searchParam : ""));
    };
    return (_jsxs(Container, { children: [_jsxs("div", Object.assign({ className: "search-wrap" }, { children: [_jsx(SearchComponent, { id: "users-search", fieldName: "users", placeholder: "Search Term", allowRouteChanges: false, valueChanged: setSearchParam, defaultValue: searchParam !== null && searchParam !== void 0 ? searchParam : "", onEnterPress: handleSearch }), _jsx("button", Object.assign({ type: "button", className: "button submit-search-button", onClick: handleSearch }, { children: "Search" }))] })), _jsxs(Table, Object.assign({ showSearchBar: false, title: "", emptyText: "Users not found.", isEmpty: (users === null || users === void 0 ? void 0 : users.length) === 0 }, { children: [_jsxs(CellHeader, Object.assign({ className: "dashboard-header section-header" }, { children: [_jsx(RawCell, { children: "ID" }), _jsx(RawCell, { children: "First Name" }), _jsx(RawCell, { children: "Last Name" }), _jsx(RawCell, { children: "Email" }), _jsx(RawCell, { children: "Title" }), _jsx(RawCell, { children: "Time Zone" }), _jsx(RawCell, { children: "Status" })] })), users &&
                        (users === null || users === void 0 ? void 0 : users.map((user, index) => (_jsxs(BasicRow, { children: [_jsx(TextCell, { children: index + 1 }), _jsx(TextCell, { children: user.firstname }), _jsx(TextCell, { children: user.lastname }), _jsx(TextCell, { children: user.email }), _jsx(TextCell, { children: user.title }), _jsx(TextCell, { children: user.timezone }), _jsx(TextCell, { children: user.status })] }, user.uuid))))] }))] }));
};
Dashboard.layout = (page) => _jsx(Layout, Object.assign({ title: "Dashboard" }, { children: page }));
export default Dashboard;

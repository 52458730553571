import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Column, MessageComponent } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { router } from "@inertiajs/react";
const FormSteps = ({ steps }) => {
    function urlCallback(val) {
        router.get(val);
    }
    const stepItems = steps === null || steps === void 0 ? void 0 : steps.map((source, index) => (_jsxs("div", Object.assign({ style: { display: "flex" } }, { children: [_jsxs("div", Object.assign({ className: "multistep-breadcrumb" }, { children: [source.url !== null ? (_jsx("a", Object.assign({ onClick: () => urlCallback(source === null || source === void 0 ? void 0 : source.url), className: "link" }, { children: source.current === true ? (_jsx(MessageComponent, { value: source.name, columnStyle: "no-padding map-warning current-breadcrumb" }, source.name + "message")) : (_jsx(MessageComponent, { value: source.name, columnStyle: "no-padding map-warning" }, source.name + "message")) }))) : (_jsx("div", { children: source.current === true ? (_jsx(MessageComponent, { value: source.name, columnStyle: "no-padding map-warning current-breadcrumb" }, source.name)) : (_jsx(MessageComponent, { value: source.name, columnStyle: "no-padding map-warning" }, source.name)) })), source.complete === true ? (_jsx(FontAwesomeIcon, { icon: "check-circle", className: "complete" }, source.name + "icon-complete")) : (_jsx(FontAwesomeIcon, { icon: "circle", className: "in-progress" }, source.name + "icon-in-progress"))] }), source.name + "form-steps-breadcrumb"), index === steps.length - 1 ? (_jsx("div", {})) : (_jsxs("div", Object.assign({ className: "breadcrumb-spacer" }, { children: [_jsx("div", { className: "inner-bar" }, source.name + "spacer-inner"), _jsx(FontAwesomeIcon, { icon: "caret-right", className: "next-step-caret" }, source.name + "next-arrow")] }), source.name + "spacer"))] }), source.name + "form-steps-wrapper")));
    return (_jsx(_Fragment, { children: _jsx(Column, Object.assign({ columnStyle: "multi-step-bread-crumbs" }, { children: stepItems }), "multi-step-bread-crumbs") }));
};
export default FormSteps;

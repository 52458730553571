import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { BasicModal } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePage } from "@inertiajs/react";
const ModalNotification = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { flash } = usePage().props;
    const modalRef = useRef(null);
    const handleModal = () => {
        var _a;
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.open();
    };
    const handleSubmit = () => {
        var _a;
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
    };
    useEffect(() => {
        var _a;
        if ((_a = flash === null || flash === void 0 ? void 0 : flash.notification_modal) === null || _a === void 0 ? void 0 : _a.shown) {
            handleModal();
        }
        else {
            handleSubmit();
        }
    }, [flash]);
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: "basic-modal" }, { children: _jsxs(BasicModal, Object.assign({ ref: modalRef }, { children: [_jsx("div", Object.assign({ className: "title-wrap" }, { children: (_a = flash === null || flash === void 0 ? void 0 : flash.notification_modal) === null || _a === void 0 ? void 0 : _a.title })), _jsx("div", Object.assign({ className: "message-wrap" }, { children: _jsx("div", { dangerouslySetInnerHTML: {
                                __html: (_b = flash === null || flash === void 0 ? void 0 : flash.notification_modal) === null || _b === void 0 ? void 0 : _b.message,
                            } }) })), _jsx("div", Object.assign({ className: "modal-button-wrap" }, { children: _jsxs("button", Object.assign({ type: "button", className: "button submit-button", onClick: handleSubmit, title: "Close", style: { marginLeft: "auto" } }, { children: [((_d = (_c = flash === null || flash === void 0 ? void 0 : flash.notification_modal) === null || _c === void 0 ? void 0 : _c.button) === null || _d === void 0 ? void 0 : _d.icon) && (_jsx(FontAwesomeIcon, { icon: (_f = (_e = flash === null || flash === void 0 ? void 0 : flash.notification_modal) === null || _e === void 0 ? void 0 : _e.button) === null || _f === void 0 ? void 0 : _f.icon })), (_h = (_g = flash === null || flash === void 0 ? void 0 : flash.notification_modal) === null || _g === void 0 ? void 0 : _g.button) === null || _h === void 0 ? void 0 : _h.name] })) }))] })) })) }));
};
export default ModalNotification;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { usePage, useForm } from "@inertiajs/react";
import { Table, BasicRow, RawCell, CellHeader, TextCell, SelectComponent, ActionHeader, TextComponent, } from "@truenorthmortgage/olympus";
import { convertValueToLabel } from "../../../utils/utils";
import "./Settings.scss";
const Settings = ({ model_uuid = null, settings = [], callback = null, model = null, can_edit = false, loading = false, }) => {
    const { flash } = usePage().props;
    const { data, setData, processing, errors, patch } = useForm({
        uuid: null,
        model_uuid: model_uuid,
        settings: settings,
        model: model,
    });
    useEffect(() => {
        setData("settings", settings);
    }, [settings]);
    if (model_uuid !== null && model === null) {
        throw new Error("To use the model_uuid parameter, the model parameter must also be passed to the Settings component");
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        patch("/settings/update", {
            preserveScroll: true,
        });
    };
    useEffect(() => {
        if (flash === null || flash === void 0 ? void 0 : flash.success) {
            if (callback) {
                callback();
            }
        }
    }, [flash]);
    const updateSettingsData = (key, value) => {
        setData("settings", Object.assign(Object.assign({}, data.settings), { [key]: value }));
    };
    const showFieldAccordingToValueType = (key_name, value) => {
        var _a;
        // Check if the user can edit, otherwise show the value only.
        if (can_edit !== true) {
            return JSON.stringify(value);
        }
        // Booleans return select components.
        if (typeof value === "boolean") {
            return (_jsxs(SelectComponent, Object.assign({ id: key_name, onBlur: (field_value) => updateSettingsData(key_name, field_value === "0" ? false : true), value: data.settings[key_name] === true ? "1" : "0", defaultValue: data.settings[key_name] === true ? "1" : "0" }, { children: [_jsx("option", Object.assign({ id: key_name + "true", value: "1" }, { children: "Yes" }), key_name + "true"), _jsx("option", Object.assign({ id: key_name + "false", value: "0" }, { children: "No" }), key_name + "false")] })));
        }
        // Everything else return a regular text field.
        return (_jsx(TextComponent, { id: key_name, defaultValue: (_a = data.settings[key_name]) !== null && _a !== void 0 ? _a : "", error: errors[key_name], columnStyle: " side-by-side-element", onBlur: (field_value) => updateSettingsData(key_name, field_value) }, key_name));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "full" }, { children: [_jsx("div", Object.assign({ className: "side-by-side-element" }, { children: _jsx(ActionHeader, { title: "Settings", widgetClassName: "no-margin no-padding" }, "settings-action-header") })), _jsx("div", { className: "side-by-side-element right", style: { marginTop: "-10px" } })] })), _jsxs(Table, Object.assign({ className: "setting-table-component", showSearchBar: true, title: "Json Meta Data Key Value", emptyText: "Empty", isEmpty: (settings === null || settings === void 0 ? void 0 : settings.length) === 0 }, { children: [_jsxs(CellHeader, Object.assign({ className: "users-header section-header" }, { children: [_jsx(RawCell, { children: "Key" }), _jsx(RawCell, { children: "Value" })] })), settings !== null &&
                        settings !== undefined &&
                        Object.entries(settings).map(([key, value]) => (_jsxs(BasicRow, { children: [_jsx(TextCell, { children: convertValueToLabel(key !== null && key !== void 0 ? key : "N/A") }), _jsx(TextCell, { children: _jsx(_Fragment, { children: showFieldAccordingToValueType(key, value) }) })] }, Math.random())))] })), can_edit === true && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx("button", Object.assign({ type: "button", className: "button submit-search-button", disabled: processing || loading, onClick: handleSubmit }, { children: "Save" }))] }))] }));
};
export default Settings;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
const Map = ({ map_api_key, latitude, longitude, latLngCallback, }) => {
    const [googleMap, setMap] = useState();
    const [mapMarker, setMarker] = useState();
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        const loader = new Loader({
            apiKey: map_api_key,
            version: "weekly",
        });
        let map;
        loader.load().then(() => __awaiter(void 0, void 0, void 0, function* () {
            const { Map } = (yield google.maps.importLibrary("maps"));
            if (loaded == false) {
                map = new Map(document.getElementById("map"), {
                    center: { lat: latitude, lng: longitude },
                    zoom: 14,
                    disableDefaultUI: true,
                    keyboardShortcuts: false,
                    draggable: false,
                    scrollwheel: false,
                });
                setMap(map);
                setLoaded(true);
            }
            if (mapMarker == undefined) {
                const marker = new google.maps.Marker({
                    position: { lat: latitude, lng: longitude },
                    map: map,
                    draggable: true,
                });
                setMarker(marker);
                marker.addListener("dragend", () => {
                    var _a, _b, _c, _d;
                    latLngCallback((_a = marker.getPosition()) === null || _a === void 0 ? void 0 : _a.lat(), (_b = marker.getPosition()) === null || _b === void 0 ? void 0 : _b.lng());
                    map.panTo({
                        lat: Number((_c = marker.getPosition()) === null || _c === void 0 ? void 0 : _c.lat()),
                        lng: Number((_d = marker.getPosition()) === null || _d === void 0 ? void 0 : _d.lng()),
                    });
                });
            }
            if (mapMarker != undefined) {
                mapMarker.setPosition({ lat: latitude, lng: longitude });
                googleMap.panTo({ lat: latitude, lng: longitude });
            }
        }));
    }, [latitude, longitude]);
    return (_jsx(_Fragment, { children: _jsx("div", { id: "map", className: "property-map" }) }));
};
export default Map;

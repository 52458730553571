export const bank_institution_numbers = {
    "001": "Bank of Montreal    ",
    "002": "Scotiabank (The Bank of Nova Scotia)    ",
    "003": "Royal Bank of Canada    ",
    "004": "The Toronto-Dominion Bank   ",
    "006": "National Bank of Canada ",
    "010": "Canadian Imperial Bank of Commerce  ",
    "016": "HSBC Bank Canada    ",
    "030": "Canadian Western Bank   ",
    "039": "Laurentian Bank of Canada   ",
    "177": "Bank of Canada  ",
    "219": "Alberta Treasury Branches   ",
    "240": "Royal Bank of Scotland N.V. (Canada Branch) ",
    "241": "Bank of America, National Association   ",
    "242": "The Bank of New York Mellon ",
    "245": "Bank of Tokyo-Mitsubishi UFJ (Canada)   ",
    "250": "BNP Paribas (Canada)    ",
    "260": "Citibank Canada ",
    "265": "Deutsche Bank AG    ",
    "269": "Mega International Commercial Bank (Canada) ",
    "270": "JPMorgan Chase Bank National Association    ",
    "275": "Korea Exchange Bank of Canada   ",
    "277": "Mizuho Corporate Bank Ltd. Canada Branch    ",
    "290": "UBS Bank (Canada)   ",
    "292": "Société Générale (Canada Branch)    ",
    "294": "State Bank of India (Canada) Alberta    ",
    "301": "Sumitomo Mitsui Banking Corporation of Canada   ",
    "303": "Amex Bank of Canada ",
    "307": "Industrial and Commercial Bank of China (Canada)    ",
    "308": "Bank of China (Canada)  ",
    "309": "Citizens Bank of Canada ",
    "310": "First Nations Bank of Canada    ",
    "311": "BofA Canada Bank    ",
    "314": "J.P. Morgan Bank Canada ",
    "315": "CTC Bank of Canada  ",
    "318": "U.S. Bank National Association  ",
    "321": "Habib Canadian Bank ",
    "322": "Rabobank Nederland  ",
    "323": "Capital One Bank (Canada Branch)    ",
    "326": "President’s Choice Financial    ",
    "327": "State Street    ",
    "328": "Citibank N.A.   ",
    "330": "Comerica Bank   ",
    "332": "First Commercial Bank   ",
    "333": "HSBC Bank USA National Association  ",
    "334": "Pacific & Western Bank of Canada    ",
    "335": "United Overseas Bank Limited    ",
    "336": "Maple Bank  ",
    "338": "Canadian Tire Bank  ",
    "339": "UBS AG Canada Branch    ",
    "340": "ICICI Bank Canada   ",
    "342": "Bank West   ",
    "343": "Dundee Bank of Canada   ",
    "344": "General Bank of Canada  ",
    "345": "Fifth Third Bank    ",
    "346": "Société Générale (Canada Branch) Ontario    ",
    "347": "Bridgewater Bank    ",
    "349": "The Northern Trust Company Canada Branch    ",
    "352": "DirectCash Bank ",
    "357": "M&T Bank    ",
    "358": "HomEquity Bank  ",
    "359": "Walmart Canada Bank ",
    "360": "Barclay’s Bank PLC Canada Branch    ",
    "361": "MonCana Bank of Canada  ",
    "507": "Community Trust Company ",
    "509": "The Canada Trust Company    ",
    "522": "Laurentian Trust of Canada Inc. ",
    "532": "Effort Trust Company    ",
    "536": "Investors Group Trust Co. Ltd.  ",
    "540": "Manulife Bank of Canada ",
    "548": "CIBC Trust Corporation  ",
    "550": "Montreal Trust Company of Canada    ",
    "551": "Sun Life Financial Trust Inc.   ",
    "568": "Peace Hills Trust Company   ",
    "570": "Royal Trust Company ",
    "580": "Royal Trust Corporation of Canada   ",
    "590": "National Trust Company  ",
    "592": "Royal Bank Mortgage Corporation ",
    "597": "TD Mortgage Corporation ",
    "603": "TD Pacific Mortgage Corporation ",
    "604": "HSBC Mortgage Corporation (Canada)  ",
    "606": "Scotia Mortgage Corporation ",
    "608": "CS Alterna Bank ",
    "614": "ING Bank of Canada  ",
    "620": "ResMor Trust Company    ",
    "621": "Peoples Trust Company   ",
    "623": "The Equitable Trust Company ",
    "625": "Industrial Alliance Trust Inc.  ",
    "626": "Manulife Trust Company  ",
    "630": "Household Trust Company ",
    "803": "Latvian Credit Union Limited    ",
    "807": "Communication Technologies Credit Union Limited ",
    "808": "Arnstein Community Credit Union Limited ",
    "810": "All Trans Financial Services Credit Union Limited   ",
    "815": "La Confédération des Caisses Populaires et D’Économie Desjardins du Québec  ",
    "819": "La Fédération des caisses populaires du Manitoba Inc.   ",
    "829": "La Fédération des Caisses Populaires de l’Ontario Inc.  ",
    "830": "Airline Financial Credit Union Limited  ",
    "837": "Meridian Credit Union   ",
    "839": "Atlantic Central    ",
    "840": "Dundalk District Credit Union Limited   ",
    "842": "Alterna Savings and Credit Union    ",
    "844": "Goderich Community Credit Union Limited ",
    "846": "Ontario Civil Service Credit Union Limited  ",
    "853": "Concentra Financial Services Association    ",
    "854": "Golden Horseshoe Credit Union Limited   ",
    "865": "La Fédération des Caisses Populaires Acadiennes Limitée ",
    "879": "Credit Union Central of Manitoba Limited    ",
    "889": "Credit Union Central of Saskatchewan    ",
    "890": "Alliance des caisses populaires de l’Ontario Limitée    ",
    "899": "Credit Union Central Alberta Limited    ",
};

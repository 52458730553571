import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatNumber, getPercent } from "../../utils/utils";
const ProgressBarUnits = ({ elements = [{ units: 0 }], total = 0, show_max = true, show_text = true, style = null, total_label = null, total_class = null, total_show_dollar_sign = true, }) => {
    const total_of_elements = elements.reduce((total, current_value) => { var _a; return total + ((_a = current_value === null || current_value === void 0 ? void 0 : current_value.units) !== null && _a !== void 0 ? _a : 0); }, 0);
    const percent = getPercent(total, total_of_elements);
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: "progress-wrap", style: style }, { children: [show_max && (_jsx("div", Object.assign({ className: "progress-bar-max", style: {
                        left: percent == 0 ? 100 : percent + "%",
                    } }, { children: _jsx("div", { children: _jsx("span", { children: "Max" }) }) }))), _jsxs("div", Object.assign({ className: "progress-bar" }, { children: [elements.map((element, index) => {
                            var _a, _b, _c;
                            return (_jsx("div", { className: (_a = element === null || element === void 0 ? void 0 : element.class) !== null && _a !== void 0 ? _a : "progress-bar-section section-" + (index + 1), style: {
                                    flexGrow: getPercent((_b = element === null || element === void 0 ? void 0 : element.units) !== null && _b !== void 0 ? _b : 0, total),
                                    backgroundColor: (_c = element === null || element === void 0 ? void 0 : element.color) !== null && _c !== void 0 ? _c : "revert-layer",
                                } }, Math.random()));
                        }), _jsx("div", { className: "progress-bar-section section-total", style: { flexGrow: getPercent(total - total_of_elements, total) } })] })), show_text && (_jsxs("div", Object.assign({ className: "progress-bar-text" }, { children: [_jsxs("p", { children: [total_show_dollar_sign === true ? "$" : "", formatNumber(total), " ", total_label === null || total_label === undefined ? (_jsx(_Fragment, { children: _jsx("span", Object.assign({ className: total_class !== null && total_class !== void 0 ? total_class : "section-total bold" }, { children: "listed" })) })) : (total_label)] }), elements.map((element, index) => {
                            var _a, _b, _c, _d;
                            return element.show_text === true && (_jsxs("p", { children: [element.show_dollar_sign === true ? "$" : "", formatNumber(element.units), " ", element.text === null || element.text === undefined ? (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "section-" + (index + 1) }, { children: (_a = element === null || element === void 0 ? void 0 : element.label) !== null && _a !== void 0 ? _a : "" })), (element === null || element === void 0 ? void 0 : element.link) !== null &&
                                                (element === null || element === void 0 ? void 0 : element.link) !== undefined && (_jsxs("a", Object.assign({ href: (_b = element.link) === null || _b === void 0 ? void 0 : _b.url }, { children: [" ", _jsx("i", { className: "fa-regular fa-arrow-up-right-from-square", style: { color: "blue" }, title: (_d = (_c = element === null || element === void 0 ? void 0 : element.link) === null || _c === void 0 ? void 0 : _c.title) !== null && _d !== void 0 ? _d : "Link" })] })))] })) : (element.text)] }, Math.random()));
                        })] })))] })) }));
};
export default ProgressBarUnits;

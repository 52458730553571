import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { usePage } from "@inertiajs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { store, pushNotification } from "@truenorthmortgage/olympus";
const Notification = (props) => {
    const { flash, errors } = usePage().props;
    useEffect(() => {
        if (flash.success) {
            store.dispatch(pushNotification({
                message: flash.success,
                class: "success",
            }));
        }
        if (flash.error) {
            if (Array.isArray(flash.error)) {
                let error_message = "<ul>";
                flash.error.map((error) => {
                    error_message += error !== undefined ? "<li>" + error + "</li>" : "";
                });
                error_message += "</ul>";
                store.dispatch(pushNotification({
                    message: error_message,
                    class: "error",
                }));
            }
            else {
                store.dispatch(pushNotification({
                    message: flash.error,
                    class: "error",
                }));
            }
        }
        if (Object.keys(errors).length > 0) {
            let error_message = "<ul>";
            Object.keys(errors).map((attr) => {
                error_message +=
                    errors[attr][0] !== undefined
                        ? "<li>" + errors[attr][0] + "</li>"
                        : "";
            });
            error_message += "</ul>";
            store.dispatch(pushNotification({
                message: error_message,
                class: "error",
            }));
        }
    }, [errors, flash]);
    const [style, setStyle] = useState({
        height: "auto",
        opacity: 1,
    });
    useEffect(() => {
        setStyle({
            height: "auto",
            opacity: 1,
        });
    }, []);
    const toggleNotification = () => {
        setStyle({
            height: "auto",
            opacity: 0,
        });
    };
    return (props === null || props === void 0 ? void 0 : props.login) === true ? (_jsx("div", Object.assign({ id: "notifications" }, { children: _jsxs("div", Object.assign({ className: `notification status-open`, style: style }, { children: [flash.error === null && flash.success && (_jsxs("div", Object.assign({ className: "notification-inner success" }, { children: [_jsx("i", Object.assign({ className: "close" }, { children: _jsx(FontAwesomeIcon, { icon: faClose, onClick: toggleNotification }) })), _jsx("span", { children: flash.success })] }))), flash.error !== null && (_jsxs("div", Object.assign({ className: "notification-inner error" }, { children: [_jsx("i", Object.assign({ className: "close" }, { children: _jsx(FontAwesomeIcon, { icon: faClose, onClick: toggleNotification }) })), Array.isArray(flash.error) &&
                            flash.error.map((error) => (_jsx("li", { children: _jsx("span", { children: error }) }, error))), Array.isArray(flash.error) === false && _jsx("span", { children: flash.error })] }))), Object.keys(errors).length > 0 && (_jsxs("div", Object.assign({ className: "notification-inner error" }, { children: [_jsx("i", Object.assign({ className: "close" }, { children: _jsx(FontAwesomeIcon, { icon: faClose, onClick: toggleNotification }) })), Object.keys(errors).map((attr) => (_jsx("li", { children: _jsx("span", { children: errors[attr][0] }) }, attr)))] })))] })) }))) : null;
};
export default Notification;

import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Pagination } from "@truenorthmortgage/olympus";
import { usePage } from "@inertiajs/react";
import { updatePagination } from "../../utils/utils";
const KratosPagination = () => {
    const { total_pages, page } = usePage().props;
    const url = usePage().url;
    const [currentPage, setCurrentPage] = useState(Number(page));
    const [currentTotalPage, setCurrentTotalPage] = useState(Number(total_pages));
    const handlePagination = (val) => {
        if (val == undefined) {
            return;
        }
        if (page == undefined) {
            return;
        }
        setCurrentPage(Number(val));
        updatePagination(Number(val), Number(page), url);
    };
    useEffect(() => {
        setCurrentPage(Number(page));
    }, [page]);
    useEffect(() => {
        setCurrentTotalPage(Number(total_pages));
    }, [total_pages]);
    return (_jsx(_Fragment, { children: _jsx(Pagination, { id: "investment_pagination", className: "pagination-main", currentPage: currentPage !== null && currentPage !== void 0 ? currentPage : 1, totalPaginationItems: currentTotalPage < 7 ? currentTotalPage : 7, totalPages: currentTotalPage, onChange: handlePagination }, "pagination_key") }));
};
export default KratosPagination;

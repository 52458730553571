import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatNumber, getPercent } from "../../utils/utils";
const ProgressBar = ({ active = 0, draft = 0, total = 0, showMax = true, showText = true, style = null, text = null, showDollarSign = false, }) => {
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: "progress-wrap", style: style }, { children: [showMax && (_jsx("div", Object.assign({ className: "progress-bar-max", style: {
                        left: (getPercent(total, active + draft) == 0
                            ? 100
                            : getPercent(total, active + draft)) + "%",
                    } }, { children: _jsx("div", { children: _jsx("span", { children: "Max" }) }) }))), _jsxs("div", Object.assign({ className: "progress-bar" }, { children: [_jsx("div", { className: "progress-bar-section section1", style: { flexGrow: getPercent(active, total) } }), _jsx("div", { className: "progress-bar-section section2", style: { flexGrow: getPercent(draft, total) } }), _jsx("div", { className: "progress-bar-section section3", style: { flexGrow: getPercent(total - (active + draft), total) } })] })), showText && (_jsxs("div", Object.assign({ className: "progress-bar-text" }, { children: [showDollarSign === true ? "$" : "", formatNumber(active), " ", text === null ? (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "color_section1" }, { children: "active" })), " listings"] })) : (text[0]), _jsx("br", {}), showDollarSign === true ? "$" : "", formatNumber(draft), " ", text === null ? (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "color_section2" }, { children: "draft" })), " listings"] })) : (text[1]), _jsx("br", {}), showDollarSign === true ? "$" : "", formatNumber(total), " ", text === null ? (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "bold" }, { children: "total" })), " investment units"] })) : (text[2])] })))] })) }));
};
export default ProgressBar;

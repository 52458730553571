import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
const ErrorPage = () => {
    const goHome = () => {
        window.location.replace("/dashboard");
    };
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ id: "wrapper" }, { children: _jsx("div", Object.assign({ id: "error-page", style: {
                    top: "50%",
                    margin: "0 auto",
                    position: "absolute",
                    width: "100%",
                    textAlign: "center",
                } }, { children: _jsx("div", { children: _jsxs("p", Object.assign({ style: { fontSize: 18 } }, { children: ["Something went wrong on our end. Our team has been notified, and we are working to fix the issue. Please", " ", _jsx("a", Object.assign({ className: "bold", style: { cursor: "pointer" }, onClick: goHome, id: "go-home-link" }, { children: "Click here" })), " ", "to go to the home page."] })) }) })) })) }));
};
export default ErrorPage;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { usePage, router } from "@inertiajs/react";
import Layout from "../../Shared/Layout";
import { Container, Column, DateComponent } from "@truenorthmortgage/olympus";
const AresSettings = () => {
    var _a;
    const { meta_data, errors } = usePage().props;
    const [epoch_date, setEpochDate] = useState((_a = meta_data === null || meta_data === void 0 ? void 0 : meta_data.epoch_date) === null || _a === void 0 ? void 0 : _a.value);
    function handleSubmit() {
        // Submit values sent to the server
        const submitValues = {
            epoch_date: epoch_date !== null && epoch_date !== void 0 ? epoch_date : null,
        };
        router.patch("/system/ares-update", submitValues);
    }
    return (_jsx(_Fragment, { children: _jsxs(Container, Object.assign({ className: "investment-landing-header" }, { children: [_jsx(Column, Object.assign({ columnStyle: "default-investment side-by-side-element right-column" }, { children: _jsx(DateComponent, { id: "funded_date", dateFormat: "Y-m-d", onChange: setEpochDate, defaultValue: epoch_date, columnStyle: "side-by-side-element", label: "Funded Date", placeholder: "Click to set a date", error: errors === null || errors === void 0 ? void 0 : errors.funded_date }, "funded-date") })), _jsx(Column, Object.assign({ columnStyle: "full button-wrap-column" }, { children: _jsx("div", Object.assign({ className: "inner-button-wrap" }, { children: _jsx("button", Object.assign({ type: "button", className: "button submit-button", onClick: handleSubmit }, { children: "Update" })) })) }), "button-wrap-column")] }), "investment-landing-header") }));
};
AresSettings.layout = (page) => _jsx(Layout, Object.assign({ title: "Settings" }, { children: page }));
export default AresSettings;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Layout from "../../Shared/Layout";
import { Container, RateComponent, ActionHeader, Form, TooltipComponent, CheckboxSelectComponent, CheckboxOption, Column, MoneyComponent, BaseInputComponent, } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { router, usePage } from "@inertiajs/react";
import { useState } from "react";
import { faQuestionCircle, faTriangleExclamation, } from "@fortawesome/free-solid-svg-icons";
import { calcEffectiveInvestorRate, calcInvestorRate, formatNumber, unitsDividedByMultiplier, unitMaskingBlock, } from "../../../utils/utils";
import "./InvestmentListings.scss";
import ProgressBar from "../../Shared/ProgressBar";
import StatusTag from "../../Shared/StatusTag";
const Investments = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    // Properties passed from Inertia !
    const { CONSTANTS, errors, investment, total_investment_listings, investor_groups, investment_listing, } = usePage().props;
    const [unitsListedError, setUnitsListedError] = useState(false);
    const [unitSoldError, setUnitSoldError] = useState(false);
    const [effectiveInvestorRateError, setEffectiveInvestorRateError] = useState(false);
    const [unitsListed, setUnitsListed] = useState((_a = unitsDividedByMultiplier(investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.units_listed, investment === null || investment === void 0 ? void 0 : investment.units_multiplier)) !== null && _a !== void 0 ? _a : 0);
    const units_sold = (_b = unitsDividedByMultiplier(investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.units_sold, investment === null || investment === void 0 ? void 0 : investment.units_multiplier)) !== null && _b !== void 0 ? _b : 0;
    const [returnRateModifierBps, setReturnRateModifierBps] = useState((_c = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.return_rate_modifier_bps) !== null && _c !== void 0 ? _c : 0);
    const [groups, setGroups] = useState((_d = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment_listing_investor_group) !== null && _d !== void 0 ? _d : []);
    const [unitPrice, setUnitPrice] = useState((_e = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.unit_price) !== null && _e !== void 0 ? _e : 0.01);
    // Calculate the effective Investor Rate !
    const [effectiveInvestorRate, setEffectiveInvestorRate] = useState(calcEffectiveInvestorRate("", investment === null || investment === void 0 ? void 0 : investment.interest_rate, investment === null || investment === void 0 ? void 0 : investment.servicing_rate, 0));
    const [investorRate] = useState(calcInvestorRate(investment === null || investment === void 0 ? void 0 : investment.interest_rate, investment === null || investment === void 0 ? void 0 : investment.servicing_rate));
    const maxUnitsAvailable = unitsDividedByMultiplier(((_f = investment === null || investment === void 0 ? void 0 : investment.max_units_available) !== null && _f !== void 0 ? _f : 0) -
        ((_g = total_investment_listings === null || total_investment_listings === void 0 ? void 0 : total_investment_listings.active) !== null && _g !== void 0 ? _g : 0), investment === null || investment === void 0 ? void 0 : investment.units_multiplier);
    function handleSubmit() {
        var _a, _b;
        const submitValues = {
            investment_listing_uuid: investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.uuid,
            investment_uuid: investment === null || investment === void 0 ? void 0 : investment.uuid,
            units_listed: unitsListed,
            return_rate_modifier_bps: ((_a = investment === null || investment === void 0 ? void 0 : investment.ownership_certificate) === null || _a === void 0 ? void 0 : _a.holder_type) ===
                CONSTANTS.OWNERSHIP_CERTIFICATE.HOLDER_TYPE.INVESTOR.value
                ? (_b = investment === null || investment === void 0 ? void 0 : investment.ownership_certificate) === null || _b === void 0 ? void 0 : _b.rate_modifier
                : returnRateModifierBps,
            status: investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.status,
            investor_group: groups,
            unit_price: unitPrice,
            activate: false,
        };
        router.patch("/investment-listings/update", submitValues, {
            preserveScroll: true,
        });
    }
    function handleSubmitActivate() {
        var _a, _b;
        const submitValues = {
            investment_listing_uuid: investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.uuid,
            investment_uuid: investment === null || investment === void 0 ? void 0 : investment.uuid,
            units_listed: unitsListed,
            return_rate_modifier_bps: ((_a = investment === null || investment === void 0 ? void 0 : investment.ownership_certificate) === null || _a === void 0 ? void 0 : _a.holder_type) ===
                CONSTANTS.OWNERSHIP_CERTIFICATE.HOLDER_TYPE.INVESTOR.value
                ? (_b = investment === null || investment === void 0 ? void 0 : investment.ownership_certificate) === null || _b === void 0 ? void 0 : _b.rate_modifier
                : returnRateModifierBps,
            status: investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.status,
            investor_group: groups,
            unit_price: unitPrice,
            activate: true,
        };
        router.patch("/investment-listings/update", submitValues, {
            preserveScroll: true,
        });
    }
    // Callback for Units Available
    function unitsListedCallback(units_listed) {
        units_listed = units_listed
            ? Number(units_listed.toString().replace(/,/g, ""))
            : 0;
        setUnitsListed(units_listed);
        if (units_listed > maxUnitsAvailable) {
            setUnitsListedError(true);
        }
        else {
            setUnitsListedError(false);
        }
        if (units_listed > 0 && Number(units_listed) < Number(units_sold)) {
            setUnitSoldError(true);
        }
        else {
            setUnitSoldError(false);
        }
    }
    // Callback for the Return Rate Modifier !
    const returnRateModifierCallback = (return_rate_modifier) => {
        return_rate_modifier = return_rate_modifier
            ? Number(return_rate_modifier)
            : 0;
        setReturnRateModifierBps(return_rate_modifier);
        const effectiveInvestorRateCalc = calcEffectiveInvestorRate("", investment === null || investment === void 0 ? void 0 : investment.interest_rate, investment === null || investment === void 0 ? void 0 : investment.servicing_rate, return_rate_modifier);
        setEffectiveInvestorRate(effectiveInvestorRateCalc);
        if (Number(effectiveInvestorRate) > Number(investorRate)) {
            setEffectiveInvestorRateError(true);
        }
        else {
            setEffectiveInvestorRateError(false);
        }
    };
    function groupsCallback(val) {
        setGroups(val);
    }
    function unitPriceCallback(val) {
        setUnitPrice(val);
    }
    const investorGroups = investor_groups === null || investor_groups === void 0 ? void 0 : investor_groups.map((source) => (_jsx(CheckboxOption, { label: source === null || source === void 0 ? void 0 : source.description, value: source === null || source === void 0 ? void 0 : source.uuid }, source === null || source === void 0 ? void 0 : source.uuid)));
    return (_jsx(_Fragment, { children: _jsxs(Container, Object.assign({ className: "investment-listing-landing-header" }, { children: [_jsxs(Column, Object.assign({ columnStyle: "full bottom-padding align-center" }, { children: [_jsx(ActionHeader, { title: "Details", widgetClassName: "no-margin" }), _jsx(StatusTag, { status: investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.status, subtitle: investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.friendly_id })] }), "edit-investment-listings-column"), _jsx(Form, Object.assign({ className: "edit-investment-form" }, { children: _jsxs(Column, Object.assign({ columnStyle: "full" }, { children: [_jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Investment ID" }), _jsx("a", Object.assign({ title: "View Investment", href: "/investments/view/" + (investment === null || investment === void 0 ? void 0 : investment.uuid) }, { children: investment === null || investment === void 0 ? void 0 : investment.friendly_id }))] })), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Ownership Certificate" }), _jsx("div", { children: _jsx("a", Object.assign({ title: "View Owner", href: "/investments/ownership-certificates/view/" +
                                                ((_h = investment === null || investment === void 0 ? void 0 : investment.ownership_certificate) === null || _h === void 0 ? void 0 : _h.uuid) }, { children: (_j = investment === null || investment === void 0 ? void 0 : investment.ownership_certificate) === null || _j === void 0 ? void 0 : _j.owner_name })) })] })), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Units Listed" }), _jsx(BaseInputComponent, { id: "units_available", disabled: (investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.status) == "active" ? true : false, inputValue: formatNumber(unitsListed), placeholder: (investment === null || investment === void 0 ? void 0 : investment.units_multiplier) == 1 ? "0" : "0.00", onChange: unitsListedCallback, maskingBlocks: unitMaskingBlock(investment === null || investment === void 0 ? void 0 : investment.units_multiplier), lazyMasking: true, error: (errors === null || errors === void 0 ? void 0 : errors.units_available) || unitsListedError, mask: "num" }, "units_available")] })), _jsx(MoneyComponent, { id: "unit_price", label: "Unit Price", columnStyle: "side-by-side-element", value: unitPrice.toString(), error: errors === null || errors === void 0 ? void 0 : errors.unit_price, disabled: (investment === null || investment === void 0 ? void 0 : investment.type) == "stock" && status !== "active"
                                    ? false
                                    : true, onChange: unitPriceCallback }, "unit_price"), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Units Sold" }), formatNumber(units_sold, investment === null || investment === void 0 ? void 0 : investment.units_multiplier), unitSoldError && (_jsx("span", Object.assign({ style: { fontWeight: "bold", marginBottom: 10 }, className: "form-error-message" }, { children: "Units Sold cant be higher than units listed." })))] })), _jsx(RateComponent, { label: "Return Rate Modifier", id: "return_rate_modifier", defaultValue: returnRateModifierBps, error: errors.return_rate_modifier && errors.return_rate_modifier[0], onChange: returnRateModifierCallback, columnStyle: " side-by-side-element", disabled: (investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.status) == "active" ||
                                    (((_k = investment === null || investment === void 0 ? void 0 : investment.ownership_certificate) === null || _k === void 0 ? void 0 : _k.holder_type) !==
                                        CONSTANTS.OWNERSHIP_CERTIFICATE.HOLDER_TYPE.ISSUER.value &&
                                        ((_l = investment === null || investment === void 0 ? void 0 : investment.ownership_certificate) === null || _l === void 0 ? void 0 : _l.holder_type) !==
                                            CONSTANTS.OWNERSHIP_CERTIFICATE.HOLDER_TYPE.ISSUER_RETAINED
                                                .value) }, "return_rate_modifier"), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsxs("div", Object.assign({ className: "wrap grey" }, { children: [_jsx("label", { children: "Total Investment Units Listed" }), _jsx(TooltipComponent, Object.assign({ id: "icon", icon: faQuestionCircle, iconClass: "grey" }, { children: _jsx("div", { children: "The total number of units listed, in draft, and available to be listed for this investment." }) }))] })), _jsx(ProgressBar, { active: unitsDividedByMultiplier((_m = total_investment_listings === null || total_investment_listings === void 0 ? void 0 : total_investment_listings.active) !== null && _m !== void 0 ? _m : 0, investment === null || investment === void 0 ? void 0 : investment.units_multiplier) +
                                            ((investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.status) === "active" ? unitsListed : 0), draft: unitsDividedByMultiplier((_o = total_investment_listings === null || total_investment_listings === void 0 ? void 0 : total_investment_listings.draft) !== null && _o !== void 0 ? _o : 0, investment === null || investment === void 0 ? void 0 : investment.units_multiplier) + ((investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.status) === "draft" ? unitsListed : 0), total: unitsDividedByMultiplier((_p = investment === null || investment === void 0 ? void 0 : investment.units_available) !== null && _p !== void 0 ? _p : 0, investment === null || investment === void 0 ? void 0 : investment.units_multiplier) })] })), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsxs("div", Object.assign({ className: "wrap grey" }, { children: [_jsx("label", { children: "Investor Rate" }), _jsx(TooltipComponent, Object.assign({ id: "icon", icon: faQuestionCircle, iconClass: "grey" }, { children: _jsx("div", { children: "(investment rate - servicing rate) for this investment" }) }))] })), _jsxs("div", { children: [((_q = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _q === void 0 ? void 0 : _q.interest_type) == "variable"
                                                ? "Prime + "
                                                : "", investorRate, "%"] }), _jsxs("div", Object.assign({ className: "wrap grey" }, { children: [_jsx("label", { children: "Effective Investor Rate" }), _jsx(TooltipComponent, Object.assign({ id: "icon", icon: faQuestionCircle, iconClass: "grey" }, { children: _jsx("div", { children: "(investor rate + return rate modifier)" }) }))] })), effectiveInvestorRateError ? (_jsxs("div", Object.assign({ className: "wrap red" }, { children: [_jsxs("div", Object.assign({ className: "warning" }, { children: [((_r = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _r === void 0 ? void 0 : _r.interest_type) == "variable"
                                                        ? "Prime + "
                                                        : "", effectiveInvestorRate, "%"] })), _jsx(TooltipComponent, Object.assign({ id: "icon", icon: faTriangleExclamation, iconClass: "red" }, { children: _jsx("div", { children: "This listing offers this investment at a loss to the Issuing Investor." }) }))] }))) : (_jsxs("div", { children: [((_s = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _s === void 0 ? void 0 : _s.interest_type) == "variable"
                                                ? "Prime + "
                                                : "", effectiveInvestorRate, "%"] }))] })), _jsx(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: _jsx("div", Object.assign({ className: "checkbox-wrap" }, { children: _jsx(CheckboxSelectComponent, Object.assign({ label: "Investment Listing Groups", onChange: groupsCallback, values: groups !== null && groups !== void 0 ? groups : [] }, { children: investorGroups ? (investorGroups) : (_jsx(CheckboxOption, { label: "", value: "" })) })) })) })), _jsx(Column, Object.assign({ columnStyle: "full button-wrap-column" }, { children: _jsxs("div", Object.assign({ className: "inner-button-wrap" }, { children: [(investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.status) !== "active" && (_jsxs("button", Object.assign({ type: "button", id: "submit-activate", className: "button submit-button", onClick: handleSubmitActivate }, { children: [_jsx(FontAwesomeIcon, { icon: "toggle-on" }), "Save and Activate"] }))), _jsxs("button", Object.assign({ id: "submit-save", type: "button", className: "button submit-button", onClick: handleSubmit }, { children: [_jsx(FontAwesomeIcon, { icon: "save" }), "Save"] }))] })) }), "button-wrap-column")] }), "edit-investment-column") }), "edit-investment-form")] })) }));
};
Investments.layout = (page) => (_jsx(Layout, Object.assign({ title: "Edit Investment Listing" }, { children: page })));
export default Investments;

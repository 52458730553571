import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store, routerDOM, IntlProvider } from "@truenorthmortgage/olympus";
import { createInertiaApp } from "@inertiajs/react";
import { datadogRum } from "@datadog/browser-rum";
import ErrorBoundary from "./Shared/ErrorBoundary";
import RollbarProvider from "../providers/RollbarProvider";
datadogRum.init({
    applicationId: window.REACT_APP_DATADOG_RUM_APPLICATION_ID,
    clientToken: window.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "morcado-admin",
    env: window.REACT_APP_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: window.REACT_APP_VERSION,
    sessionSampleRate: window.REACT_APP_DATADOG_SAMPLE_RATE,
    sessionReplaySampleRate: window.REACT_APP_DATADOG_REPLAY_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
});
datadogRum.startSessionReplayRecording();
const rootElement = document.getElementById("app");
const root = createRoot(rootElement);
createInertiaApp({
    progress: {
        delay: 250,
        color: "#6f4f9e",
        includeCSS: true,
        showSpinner: true,
    },
    resolve: (name) => require(`./Pages/${name}`),
    title: (title) => (title ? `${title} - Kratos` : "Kratos"),
    setup({ App, props }) {
        var _a, _b;
        root.render(_jsx(React.StrictMode, { children: _jsx(ErrorBoundary, { children: _jsx(routerDOM.BrowserRouter, { children: _jsx(Provider, Object.assign({ store: store }, { children: _jsx(IntlProvider, { children: _jsx(RollbarProvider, Object.assign({ auth: (_b = (_a = props === null || props === void 0 ? void 0 : props.initialPage) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.auth }, { children: _jsx(App, Object.assign({}, props)) })) }) })) }) }) }));
    },
});

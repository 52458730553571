import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { usePage, useForm } from "@inertiajs/react";
import Layout from "../../Shared/Layout";
import { Container, Form, TextComponent, Column, ActionHeader, } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Issuers.scss";
import StatusTag from "../../Shared/StatusTag";
const CreateIssuer = () => {
    var _a, _b, _c, _d, _e, _f;
    const { issuer } = usePage().props;
    const { data, setData, patch, post, processing, errors } = useForm({
        uuid: (_a = issuer === null || issuer === void 0 ? void 0 : issuer.uuid) !== null && _a !== void 0 ? _a : "",
        name: (_b = issuer === null || issuer === void 0 ? void 0 : issuer.name) !== null && _b !== void 0 ? _b : "",
        url: (_c = issuer === null || issuer === void 0 ? void 0 : issuer.url) !== null && _c !== void 0 ? _c : "",
        required_ownership_percentage: (_d = issuer === null || issuer === void 0 ? void 0 : issuer.required_ownership_percentage) !== null && _d !== void 0 ? _d : "",
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        if (issuer === null || issuer === void 0 ? void 0 : issuer.uuid) {
            patch("/issuers/" + data.uuid);
        }
        else {
            post("/issuers/save");
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(Container, Object.assign({ className: "issuers-landing-header" }, { children: [_jsx(ActionHeader, { title: "Issuer", widgetClassName: "no-margin" }), _jsx(StatusTag, { status: (_e = issuer === null || issuer === void 0 ? void 0 : issuer.status) !== null && _e !== void 0 ? _e : "draft", subtitle: (_f = issuer === null || issuer === void 0 ? void 0 : issuer.uuid) !== null && _f !== void 0 ? _f : "" }), _jsx(Form, Object.assign({ className: "form-container" }, { children: _jsxs(Column, Object.assign({ columnStyle: "full" }, { children: [_jsx(TextComponent, { id: "name", label: "Name", defaultValue: data.name, error: (errors === null || errors === void 0 ? void 0 : errors.name) ? true : false, columnStyle: " side-by-side-element", onBlur: (value) => setData("name", value) }, "name"), _jsx(TextComponent, { id: "url", label: "URL", defaultValue: data.url, error: (errors === null || errors === void 0 ? void 0 : errors.url) ? true : false, columnStyle: " side-by-side-element", onBlur: (value) => setData("url", value) }, "url"), _jsx("div", Object.assign({ className: "side-by-side-element" }, { children: _jsx(TextComponent, { id: "required_ownership_percentage", label: "Required Ownership Percentage", defaultValue: data.required_ownership_percentage, error: (errors === null || errors === void 0 ? void 0 : errors.required_ownership_percentage) ? true : false, onBlur: (value) => setData("required_ownership_percentage", value) }, "required_ownership_percentage") })), _jsxs("button", Object.assign({ type: "button", disabled: processing, className: "button submit-button", onClick: (e) => handleSubmit(e) }, { children: [_jsx(FontAwesomeIcon, { icon: "save" }), " ", (data === null || data === void 0 ? void 0 : data.uuid) ? "Save" : "Create"] }))] }), "form-columns") }))] })) }));
};
CreateIssuer.layout = (page) => _jsx(Layout, Object.assign({ title: "Issuer" }, { children: page }));
export default CreateIssuer;

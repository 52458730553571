import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ErrorPage from "../Pages/Error";
function ErrorBoundary({ children }) {
    class ErrorBoundary extends React.Component {
        constructor(props) {
            super(props);
            this.state = { hasError: false };
        }
        static getDerivedStateFromError() {
            return { hasError: true };
        }
        componentDidCatch(error, errorInfo) {
            console.error(error, errorInfo);
        }
        render() {
            if (this.state.hasError) {
                return _jsx(ErrorPage, {});
            }
            return this.props.children;
        }
    }
    return _jsx(ErrorBoundary, { children: children });
}
export default ErrorBoundary;

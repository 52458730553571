import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { router, usePage } from "@inertiajs/react";
import Layout from "../../Shared/Layout";
import { Container, BasicRow, TextCell, Table, CellHeader, RawCell, ActionButton, ActionButtonsCell, SearchComponent, } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KratosPagination from "../../Shared/KratosPagination";
import { getParamFromUrlString } from "../../../utils/utils";
const Investors = () => {
    const url = usePage().url;
    const { CONSTANTS, investors } = usePage().props;
    const [searchParam, setSearchParam] = useState(getParamFromUrlString(url, "search_investment"));
    const investorItems = investors === null || investors === void 0 ? void 0 : investors.map((source) => (_jsxs(BasicRow, Object.assign({ className: "faux-row" }, { children: [_jsxs(TextCell, Object.assign({ className: "investor-type" }, { children: [source.type === CONSTANTS.INVESTOR_ACCOUNT.TYPE.PERSONAL.value ? (_jsx(FontAwesomeIcon, { icon: "user", style: { marginLeft: 5, marginRight: 5 } })) : (_jsx(FontAwesomeIcon, { icon: "building", style: { marginLeft: 5, marginRight: 5 } })), source.type] }), source.type + "type"), _jsx(TextCell, { children: source.friendly_id }, source.friendly_id + "friendly_id"), _jsx(TextCell, { children: source.name }, source.friendly_id + "friendly_id"), _jsxs(ActionButtonsCell, { children: [_jsx(ActionButton, Object.assign({ id: "view-" + source.uuid, title: "View", callback: () => viewCallBack(source.uuid) }, { children: _jsx(FontAwesomeIcon, { icon: "eye" }) }), "action-view"), _jsx(ActionButton, Object.assign({ id: "edit-" + source.uuid, title: "Edit", callback: () => editLinkCallBack(source.uuid) }, { children: _jsx(FontAwesomeIcon, { icon: "pencil" }) }), "action-edit"), _jsx(ActionButton, Object.assign({ id: "delete-" + source.uuid, className: "red", title: "Delete", callback: () => deleteCallback(source.uuid) }, { children: _jsx(FontAwesomeIcon, { icon: "trash" }) }), "action-delete")] })] }), Math.random())));
    function viewCallBack(val) {
        router.get("/investors/view/" + val);
    }
    // Callback for edit link !
    function editLinkCallBack(val) {
        router.get("/investors/edit/" + val);
    }
    function deleteCallback(val) {
        router.delete("investors/delete/" + val);
    }
    const handleSearch = () => {
        router.get("/investors" + (searchParam ? "?search_investors=" + searchParam : ""));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Container, Object.assign({ className: "investment-landing-header" }, { children: [_jsxs("div", Object.assign({ className: "search-wrap" }, { children: [_jsx(SearchComponent, { fieldName: "investors", placeholder: "Search by Name, DBA, or Friendly ID", allowRouteChanges: false, valueChanged: setSearchParam, defaultValue: searchParam !== null && searchParam !== void 0 ? searchParam : "", onEnterPress: handleSearch }), _jsx("button", Object.assign({ type: "button", className: "button submit-search-button", onClick: handleSearch }, { children: "Search" }))] })), _jsxs(Table, Object.assign({ title: "", showSearchBar: false, className: "investments-list-table faux-table table-builder", isEmpty: (investors === null || investors === void 0 ? void 0 : investors.length) < 1, emptyText: "Empty" }, { children: [_jsxs(CellHeader, Object.assign({ className: "investors-table-header section-header" }, { children: [_jsx(RawCell, Object.assign({ className: "faux-cell" }, { children: "Type" }), "all-investments-table-header-grade"), _jsx(RawCell, Object.assign({ className: "faux-cell" }, { children: "Friendly ID" }), "all-investments-table-header-grade"), _jsx(RawCell, Object.assign({ className: "faux-cell" }, { children: "Name/DBA" }), "all-investments-table-header-grade"), _jsx(RawCell, {}, "all-investments-actions")] }), "all-investments-table-header"), investorItems] }), "all-investments-table")] }), "investment-landing-header"), _jsx("div", Object.assign({ className: "pagination-wrap" }, { children: (investors === null || investors === void 0 ? void 0 : investors.length) > 0 && _jsx(KratosPagination, {}) }))] }));
};
Investors.layout = (page) => _jsx(Layout, Object.assign({ title: "Investment" }, { children: page }));
export default Investors;

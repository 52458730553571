import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { usePage, router } from "@inertiajs/react";
import Layout from "../../Shared/Layout";
import { ActionHeader, Widget, ActionDropdown, ActionOption, TooltipComponent, Container, Column, CheckboxSelectComponent, CheckboxOption, } from "@truenorthmortgage/olympus";
import { faQuestionCircle, faTriangleExclamation, } from "@fortawesome/free-solid-svg-icons";
import { calcEffectiveInvestorRate, calcInvestorRate, formatNumber, unitsDividedByMultiplier, } from "../../../utils/utils";
import "./InvestmentListings.scss";
import ProgressBarUnits from "../../Shared/ProgressBarUnits";
import StatusTag from "../../Shared/StatusTag";
import KratosModal from "../../Shared/Modals/KratosModal";
import PurchaseInvestmentFromListingForm from "../../Shared/Forms/PurchaseInvestment/PurchaseInvestmentFromListingForm";
const ViewInvestmentListing = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    const { investment_listing, investor_groups, investment } = usePage()
        .props;
    const [isModalPurchaseOpen, setIsModalPurchaseOpen] = useState(false);
    const deleteInvestment = () => {
        if (window.confirm("Do you really want to delete this Investment Listing?")) {
            router.delete("/investment-listings/delete/" + (investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.uuid));
        }
    };
    const editInvestmentListing = () => {
        router.get(`/investment-listings/edit/${investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.uuid}`);
    };
    // Create Investor Group Checkboxes.
    const investorGroups = investor_groups === null || investor_groups === void 0 ? void 0 : investor_groups.map((source) => (_jsx(CheckboxOption, { label: source.description, value: source.uuid, disabled: true }, source.uuid)));
    const investorRate = calcInvestorRate((_a = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _a === void 0 ? void 0 : _a.interest_rate, (_b = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _b === void 0 ? void 0 : _b.servicing_rate);
    const effectiveInvestorRate = calcEffectiveInvestorRate("", (_c = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _c === void 0 ? void 0 : _c.interest_rate, (_d = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _d === void 0 ? void 0 : _d.servicing_rate, Number(investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.return_rate_modifier_bps));
    let effectiveInvestorRateError = false;
    if (Number(effectiveInvestorRate) > Number(investorRate)) {
        effectiveInvestorRateError = true;
    }
    const recordPurchaseCallback = () => {
        setIsModalPurchaseOpen(!isModalPurchaseOpen);
    };
    return investment_listing ? (_jsxs(Container, Object.assign({ className: "investment-listing-landing-header" }, { children: [_jsx(KratosModal, Object.assign({ is_open: isModalPurchaseOpen, callback: () => setIsModalPurchaseOpen(false) }, { children: _jsx(PurchaseInvestmentFromListingForm, { callback: recordPurchaseCallback }) })), _jsxs(Column, Object.assign({ columnStyle: "full bottom-padding align-center" }, { children: [_jsx(ActionHeader, Object.assign({ title: "Details", widgetClassName: "no-margin" }, { children: _jsx(ActionDropdown, Object.assign({ id: "investment-listing-actions", inputText: "Actions" }, { children: [
                                _jsx(ActionOption, Object.assign({ clickCallback: editInvestmentListing }, { children: "Edit Listing" }), "edit"),
                                _jsx(ActionOption, Object.assign({ clickCallback: deleteInvestment }, { children: "Delete Listing" }), "delete"),
                                _jsx(ActionOption, Object.assign({ clickCallback: recordPurchaseCallback }, { children: "Record Purchase" }), "purchase"),
                            ].filter((option) => option) })) })), _jsx(StatusTag, { status: investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.status, subtitle: investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.friendly_id }), _jsx(Widget, Object.assign({ className: "no-margin mt-5 no-padding" }, { children: _jsx("div", Object.assign({ className: "column col-6-12" }, { children: _jsxs("div", Object.assign({ className: "columns" }, { children: [_jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Investment ID" }), _jsx("div", { children: _jsx("a", Object.assign({ style: { fontWeight: "bold" }, href: "/investments/view/" + (investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment_uuid) }, { children: (_e = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _e === void 0 ? void 0 : _e.friendly_id })) })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Ownership Certificate" }), _jsx("div", { children: _jsx("a", Object.assign({ title: "View Owner", href: "/investments/ownership-certificates/view/" +
                                                        ((_f = investment === null || investment === void 0 ? void 0 : investment.ownership_certificate) === null || _f === void 0 ? void 0 : _f.uuid) }, { children: (_g = investment === null || investment === void 0 ? void 0 : investment.ownership_certificate) === null || _g === void 0 ? void 0 : _g.owner_name })) })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Units Listed" }), _jsx("div", { children: formatNumber((_h = unitsDividedByMultiplier(investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.units_listed, investment === null || investment === void 0 ? void 0 : investment.units_multiplier)) !== null && _h !== void 0 ? _h : 0) })] })), _jsx("div", { className: "side-by-side-element column col-12-12" }), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Units Sold" }), formatNumber((_j = unitsDividedByMultiplier(investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.units_sold, investment === null || investment === void 0 ? void 0 : investment.units_multiplier)) !== null && _j !== void 0 ? _j : 0)] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Return Rate Modifier" }), _jsxs("div", { children: [investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.return_rate_modifier_bps, "%"] })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12" }, { children: [_jsx("label", { children: "Units Available" }), formatNumber((_k = unitsDividedByMultiplier((investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.units_listed) -
                                                (investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.units_sold), investment === null || investment === void 0 ? void 0 : investment.units_multiplier)) !== null && _k !== void 0 ? _k : 0)] })), _jsx("div", { className: "side-by-side-element column col-12-12" }), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12'" }, { children: [_jsxs("div", Object.assign({ className: "wrap grey" }, { children: [_jsx("label", { children: "Investment Units" }), _jsx(TooltipComponent, Object.assign({ id: "icon", icon: faQuestionCircle, iconClass: "grey" }, { children: _jsx("div", { children: "The total number of units listed, approved purchases pending processing, pending purchase requests, and safe to sell for this investment listing." }) }))] })), _jsx("div", Object.assign({ style: { width: "80%" } }, { children: _jsx(ProgressBarUnits, { elements: [
                                                        {
                                                            units: unitsDividedByMultiplier((_l = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.units_sold) !== null && _l !== void 0 ? _l : 0, investment === null || investment === void 0 ? void 0 : investment.units_multiplier),
                                                            label: "sold",
                                                            show_dollar_sign: true,
                                                            show_text: true,
                                                        },
                                                        {
                                                            units: unitsDividedByMultiplier((_m = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.unitsPendingPurchaseQueueByInvestmentListing) !== null && _m !== void 0 ? _m : 0, investment === null || investment === void 0 ? void 0 : investment.units_multiplier),
                                                            label: "approved purchases pending processing",
                                                            show_dollar_sign: true,
                                                            show_text: ((_o = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.unitsPendingPurchaseQueueByInvestmentListing) !== null && _o !== void 0 ? _o : 0) > 0,
                                                        },
                                                        {
                                                            units: unitsDividedByMultiplier((_p = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.unitsActionQueuePendingPurchaseByInvestmentListing) !== null && _p !== void 0 ? _p : 0, investment === null || investment === void 0 ? void 0 : investment.units_multiplier),
                                                            label: "pending purchase requests",
                                                            show_dollar_sign: true,
                                                            show_text: ((_q = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.unitsActionQueuePendingPurchaseByInvestmentListing) !== null && _q !== void 0 ? _q : 0) > 0,
                                                            link: {
                                                                url: "/action-queues",
                                                                title: "View Action Queue",
                                                            },
                                                        },
                                                        {
                                                            units: unitsDividedByMultiplier((_r = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.unitsAvailableToBeSoldConsideringPending) !== null && _r !== void 0 ? _r : 0, investment === null || investment === void 0 ? void 0 : investment.units_multiplier),
                                                            label: "safe to sell",
                                                            show_dollar_sign: true,
                                                            show_text: true,
                                                        },
                                                    ], total: unitsDividedByMultiplier((_s = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.units_listed) !== null && _s !== void 0 ? _s : 0, investment === null || investment === void 0 ? void 0 : investment.units_multiplier) }) }))] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12'" }, { children: [_jsxs("div", Object.assign({ className: "wrap grey" }, { children: [_jsx("label", { children: "Investor Rate" }), _jsx(TooltipComponent, Object.assign({ id: "icon", icon: faQuestionCircle, iconClass: "grey" }, { children: _jsx("div", { children: "(investment rate - servicing rate) for this investment" }) }))] })), _jsxs("div", { children: [((_t = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _t === void 0 ? void 0 : _t.interest_type) == "variable"
                                                        ? "Prime + "
                                                        : "", investorRate, "%"] })] })), _jsx("div", Object.assign({ className: "side-by-side-element column col-12-12'" }, { children: _jsx("div", Object.assign({ className: "checkbox-wrap" }, { children: _jsx(CheckboxSelectComponent, Object.assign({ label: "Investment Listing Groups", values: (_u = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment_listing_investor_group) !== null && _u !== void 0 ? _u : [] }, { children: investorGroups ? (investorGroups) : (_jsx(CheckboxOption, { label: "", value: "" })) })) })) })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12'" }, { children: [_jsxs("div", Object.assign({ className: "wrap grey" }, { children: [_jsx("label", { children: "Effective Investor Rate" }), _jsx(TooltipComponent, Object.assign({ id: "icon", icon: faQuestionCircle, iconClass: "grey" }, { children: _jsx("div", { children: "(investor rate + return rate modifier)" }) }))] })), effectiveInvestorRateError ? (_jsxs("div", Object.assign({ className: "wrap red" }, { children: [_jsxs("div", Object.assign({ className: "warning" }, { children: [((_v = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _v === void 0 ? void 0 : _v.interest_type) ==
                                                                "variable"
                                                                ? "Prime + "
                                                                : "", effectiveInvestorRate, "%"] })), _jsx(TooltipComponent, Object.assign({ id: "icon", icon: faTriangleExclamation, iconClass: "red" }, { children: _jsx("div", { children: "This listing offers this investment at a loss to the Issuing Investor." }) }))] }))) : (_jsxs("div", { children: [((_w = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _w === void 0 ? void 0 : _w.interest_type) == "variable"
                                                        ? "Prime + "
                                                        : "", effectiveInvestorRate, "%"] }))] }))] })) })) }))] }), "view-investment-listings-column")] }))) : null;
};
ViewInvestmentListing.layout = (page) => (_jsx(Layout, Object.assign({ title: "View Investment Listing" }, { children: page })));
export default ViewInvestmentListing;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { router, usePage } from "@inertiajs/react";
import Layout from "../../Shared/Layout";
import { ActionButton, ActionButtonsCell, BasicRow, CellHeader, Table, TextCell, Container, SearchComponent, RawCell, } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KratosPagination from "../../Shared/KratosPagination";
import { getParamFromUrlString } from "../../../utils/utils";
import "./Issuers.scss";
const Issuers = () => {
    const url = usePage().url;
    const { issuers, can_edit } = usePage().props;
    const [loading] = useState(false);
    const [searchParam, setSearchParam] = useState(getParamFromUrlString(url, "search_issuers"));
    const handleSearch = () => {
        router.get("/issuers" + (searchParam ? "?search_issuers=" + searchParam : ""));
    };
    const userActionButtons = (issuer) => {
        const actions = [
            _jsx(ActionButton, Object.assign({ id: "edit-" + issuer.uuid, title: "Edit", disabled: !can_edit || loading, callback: () => router.get("issuers/edit/" + issuer.uuid) }, { children: _jsx(FontAwesomeIcon, { icon: "pencil" }) }), "action-edit"),
        ];
        return actions;
    };
    return (_jsxs(Container, Object.assign({ className: "issuers-landing-header" }, { children: [_jsxs("div", Object.assign({ className: "search-wrap" }, { children: [_jsx(SearchComponent, { fieldName: "issuers", placeholder: "Enter Issuer's Name, Investor's Name or Investment Account's Friendly ID (e.g. 1234-ABCD-5678)", allowRouteChanges: false, valueChanged: setSearchParam, defaultValue: searchParam !== null && searchParam !== void 0 ? searchParam : "", onEnterPress: handleSearch }), _jsx("button", Object.assign({ type: "button", className: "button submit-search-button", onClick: handleSearch }, { children: "Search" }))] })), _jsxs(Table, Object.assign({ showSearchBar: false, title: "", emptyText: "Issuers not found.", isEmpty: (issuers === null || issuers === void 0 ? void 0 : issuers.length) === 0 }, { children: [_jsxs(CellHeader, Object.assign({ className: "users-header section-header" }, { children: [_jsx(RawCell, { children: "Name" }), _jsx(RawCell, { children: "URL" }), _jsx(RawCell, { children: "Required Ownership Percentage" }), _jsx(RawCell, { children: "Investor Account" }), _jsx(RawCell, { children: "Default Investment Account" }), _jsx(RawCell, { children: "Status" }), _jsx(TextCell, {})] })), issuers &&
                        (issuers === null || issuers === void 0 ? void 0 : issuers.map((issuer) => {
                            var _a;
                            return (_jsxs(BasicRow, { children: [_jsx(TextCell, { children: issuer.name }), _jsx(TextCell, { children: (_a = issuer.url) !== null && _a !== void 0 ? _a : "" }), _jsxs(TextCell, { children: [parseFloat(issuer.required_ownership_percentage).toFixed(2), "%"] }), _jsx(TextCell, { children: issuer.investorAccount.name }), _jsxs(TextCell, { children: [issuer.investmentAccount.friendly_id, " (", issuer.investmentAccount.type, ")"] }), _jsx(TextCell, Object.assign({ className: "text-uppercase" }, { children: issuer.status })), _jsx(ActionButtonsCell, Object.assign({ className: "nowrap" }, { children: userActionButtons(issuer) }))] }, issuer.uuid));
                        }))] })), _jsx("div", Object.assign({ className: "pagination-wrap" }, { children: (issuers === null || issuers === void 0 ? void 0 : issuers.length) > 0 && _jsx(KratosPagination, {}) }))] })));
};
Issuers.layout = (page) => _jsx(Layout, Object.assign({ title: "Issuers" }, { children: page }));
export default Issuers;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { usePage } from "@inertiajs/react";
import Layout from "../../Shared/Layout";
import { Container, Column } from "@truenorthmortgage/olympus";
import { formatNumberToMoneyFormat } from "../../../utils/utils";
const Settings = () => {
    const { all_settings } = usePage().props;
    const formatValue = (value, format) => {
        if (format === "money") {
            return formatNumberToMoneyFormat(parseInt(value) / 100);
        }
        return value;
    };
    return (_jsx(_Fragment, { children: _jsx(Container, Object.assign({ className: "investment-landing-header" }, { children: all_settings === null || all_settings === void 0 ? void 0 : all_settings.map((setting) => (_jsxs(Column, Object.assign({ columnStyle: "default-investment side-by-side-element right-column" }, { children: [_jsx("h1", Object.assign({ className: "capitalize" }, { children: (setting === null || setting === void 0 ? void 0 : setting.name) && setting.name.replace(/_/g, " ") }), setting === null || setting === void 0 ? void 0 : setting.name), Array.isArray(setting === null || setting === void 0 ? void 0 : setting.meta_data) &&
                        (setting === null || setting === void 0 ? void 0 : setting.meta_data.every((item) => typeof item === "object"))
                        ? setting === null || setting === void 0 ? void 0 : setting.meta_data.map((item, index) => (_jsxs("div", { children: [_jsx("label", { children: item.key }), _jsx("p", { children: item.value })] }, index)))
                        : ((setting === null || setting === void 0 ? void 0 : setting.meta_data) ? Object.keys(setting.meta_data) : []).map((key, subIndex) => {
                            var _a, _b, _c, _d;
                            return (_jsxs("div", Object.assign({ style: { marginTop: "10px", display: "flex" } }, { children: [_jsxs("label", Object.assign({ className: "capitalize" }, { children: [key && key.replace(/_/g, " "), ":"] })), _jsx("p", Object.assign({ style: { marginLeft: "5px" } }, { children: (((_a = setting.meta_data[key]) === null || _a === void 0 ? void 0 : _a.format)
                                            ? formatValue((_b = setting.meta_data[key]) === null || _b === void 0 ? void 0 : _b.value, (_c = setting.meta_data[key]) === null || _c === void 0 ? void 0 : _c.format)
                                            : (_d = setting.meta_data[key]) === null || _d === void 0 ? void 0 : _d.value) + "" }))] }), subIndex));
                        })] }), (setting === null || setting === void 0 ? void 0 : setting.name) + "_wrap"))) }), "investment-landing-header") }));
};
Settings.layout = (page) => _jsx(Layout, Object.assign({ title: "Settings" }, { children: page }));
export default Settings;

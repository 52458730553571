import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Provider, ErrorBoundary } from "@rollbar/react";
import { useEffect, useState } from "react";
const RollbarProvider = ({ auth, children }) => {
    const [rollbarProvider, setRollbarProvider] = useState(_jsx(_Fragment, {}));
    useEffect(() => {
        setRollBarConfig();
        return () => {
            setRollbarProvider(_jsx(_Fragment, { children: children }));
        };
    }, [auth]);
    const setRollBarConfig = () => {
        var _a, _b;
        if (window.REACT_APP_ENVIRONMENT != undefined) {
            if (window.REACT_APP_ENVIRONMENT === "dev") {
                setRollbarProvider(_jsx(_Fragment, { children: children }));
                return;
            }
            const rollbarConfig = {
                accessToken: window.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
                captureUncaught: true,
                captureUnhandledRejections: true,
                payload: {
                    person: {
                        id: (_a = auth === null || auth === void 0 ? void 0 : auth.user) === null || _a === void 0 ? void 0 : _a.id,
                        email: (_b = auth === null || auth === void 0 ? void 0 : auth.user) === null || _b === void 0 ? void 0 : _b.email,
                    },
                    environment: window.REACT_APP_ENVIRONMENT,
                    client: {
                        javascript: {
                            code_version: window.REACT_APP_VERSION,
                            source_map_enabled: true,
                        },
                    },
                },
            };
            setRollbarProvider(_jsx(Provider, Object.assign({ config: rollbarConfig }, { children: _jsx(ErrorBoundary, { children: children }) })));
        }
    };
    return rollbarProvider;
};
export default RollbarProvider;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { router, usePage } from "@inertiajs/react";
import Layout from "../../Shared/Layout";
import { Container, BasicRow, TextCell, Table, CellHeader, RawCell, SearchComponent, ActionButton, ActionButtonsCell, TabGroup, TabGroupTab, SelectComponent, ActionHeader, } from "@truenorthmortgage/olympus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KratosPagination from "../../Shared/KratosPagination";
import { unitsDividedByMultiplier, formatNumber, getParamFromUrlString, } from "../../../utils/utils";
import StatusTag from "../../Shared/StatusTag";
import "./EFT.scss";
const EFTs = (attributes) => {
    var _a, _b, _c, _d;
    const concierge_type = attributes.type;
    const { CONSTANTS, eft_files, eft_file_status, can_download, count_pending_pad, count_pending_pac, } = usePage().props;
    const url = usePage().url;
    const [searchParam, setSearchParam] = useState({
        search_eft_file: getParamFromUrlString(url, "search_eft_file"),
        status: (_a = getParamFromUrlString(url, "status")) !== null && _a !== void 0 ? _a : "",
        entry_type: (_b = getParamFromUrlString(url, "entry_type")) !== null && _b !== void 0 ? _b : "debit",
    });
    const [fileType, setFileType] = useState(searchParam.entry_type);
    const [loading, setLoading] = useState(false);
    const viewConciergeFileCallBack = (uuid) => {
        router.get("/efts/file/view/" + uuid, {}, {
            preserveState: true,
            onStart: () => setLoading(true),
            onFinish: () => setLoading(false),
        });
    };
    const downloadConciergeFileCallBack = (uuid) => {
        window.location.href = "/efts/file/download/" + uuid;
    };
    const editConciergeFileCallBack = (uuid) => {
        router.get("/efts/file/edit/" + uuid, {}, {
            preserveState: true,
            onStart: () => setLoading(true),
            onFinish: () => setLoading(false),
        });
    };
    const createNewEntryCallBack = () => {
        router.get("/efts/concierge-" + concierge_type + "/create/" + fileType, {}, {
            preserveState: true,
            onStart: () => setLoading(true),
            onFinish: () => setLoading(false),
        });
    };
    const handleSearch = () => {
        const search = searchParam;
        router.get("/efts/concierge-" + concierge_type, {
            status: search.status,
            entry_type: search.entry_type,
            search_eft_file: search.search_eft_file,
        }, {
            preserveState: true,
            onStart: () => setLoading(true),
            onFinish: () => setLoading(false),
        });
    };
    const handleSearchTerm = (term) => {
        const search = searchParam;
        if (term !== search.search_eft_file) {
            searchParam.search_eft_file = term;
            setSearchParam(search);
        }
    };
    const handleFilter = (status_value) => {
        const search = searchParam;
        if (status_value !== search.status) {
            searchParam.status = status_value;
            setSearchParam(search);
            handleSearch();
        }
    };
    const actionButtons = (eft_file) => {
        const actions = [];
        if (CONSTANTS.EFT_FILE.STATUS.SUBMITTED.value === eft_file.status ||
            CONSTANTS.EFT_FILE.STATUS.PENDING.value === eft_file.status) {
            actions.push(_jsx(ActionButton, Object.assign({ id: "download-" + eft_file.uuid, title: "download", disabled: loading === true || can_download === false, callback: () => downloadConciergeFileCallBack(eft_file.uuid) }, { children: _jsx(FontAwesomeIcon, { icon: "download" }) }), "action-download"));
        }
        if (CONSTANTS.EFT_FILE.STATUS.DRAFT.value !== eft_file.status &&
            CONSTANTS.EFT_FILE.STATUS.CANCELLED.value !== eft_file.status) {
            actions.push(_jsx(ActionButton, Object.assign({ id: "view-" + eft_file.uuid, title: "View", disabled: loading, callback: () => viewConciergeFileCallBack(eft_file.uuid) }, { children: _jsx(FontAwesomeIcon, { icon: "eye" }) }), "action-view"));
        }
        if (CONSTANTS.EFT_FILE.STATUS.DRAFT.value === eft_file.status) {
            actions.push(_jsx(ActionButton, Object.assign({ id: "edit-" + eft_file.uuid, title: "Edit", disabled: loading, callback: () => editConciergeFileCallBack(eft_file.uuid) }, { children: _jsx(FontAwesomeIcon, { icon: "pencil" }) }), "action-edit"));
        }
        return actions;
    };
    const handleFileType = (tab_index) => {
        const entry_type_value = tab_index === 0 ? "debit" : "credit";
        const search = searchParam;
        if (entry_type_value !== search.entry_type) {
            setFileType(entry_type_value);
            searchParam.entry_type = entry_type_value;
            setSearchParam(search);
            handleSearch();
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(Container, Object.assign({ className: "eft-files-landing-landing-header" }, { children: [_jsx(ActionHeader, { title: "Concierge " + concierge_type }), _jsxs(TabGroup, Object.assign({ onTabChange: handleFileType, selectedTab: fileType === "debit" ? 0 : 1 }, { children: [_jsx(TabGroupTab, { name: "PADs (" + count_pending_pad + ")" }), _jsx(TabGroupTab, { name: "PACs (" + count_pending_pac + ")" })] })), _jsxs("div", Object.assign({ className: "search-wrap" }, { children: [_jsx(SearchComponent, { fieldName: "eft-files", id: "eft-files-search", placeholder: "", allowRouteChanges: false, valueChanged: handleSearchTerm, defaultValue: (_c = searchParam.search_eft_file) !== null && _c !== void 0 ? _c : "", onEnterPress: handleSearch }), _jsx("button", Object.assign({ type: "button", className: "button submit-search-button", disabled: loading, onClick: handleSearch }, { children: "Search" })), _jsxs(SelectComponent, Object.assign({ id: "status-search", onChange: handleFilter, value: (_d = searchParam.status) !== null && _d !== void 0 ? _d : "all", columnStyle: "search-select" }, { children: [_jsx("option", Object.assign({ id: "all", value: "" }, { children: "Show All" }), "all"), eft_file_status &&
                                    (eft_file_status === null || eft_file_status === void 0 ? void 0 : eft_file_status.map((eft_file_status) => (_jsxs("option", Object.assign({ id: eft_file_status.value, value: eft_file_status.value }, { children: ["Show ", eft_file_status.label] }), eft_file_status.value))))] })), _jsxs(ActionButton, Object.assign({ className: "new-button", title: "New " + (fileType === "debit" ? "PAD" : "PAC"), callback: createNewEntryCallBack }, { children: [_jsx(FontAwesomeIcon, { icon: "plus" }), " New " + (fileType === "debit" ? "PAD" : "PAC")] }))] })), _jsxs(Table, Object.assign({ title: "", showSearchBar: false, className: "eft-files-table faux-table table-builder", isEmpty: (eft_files === null || eft_files === void 0 ? void 0 : eft_files.length) < 1, emptyText: "Empty" }, { children: [_jsxs(CellHeader, Object.assign({ className: "eft-files-table-header section-header" }, { children: [_jsx(RawCell, Object.assign({ className: "faux-cell" }, { children: "File Number" }), "eft-files-table-header-file-number"), _jsx(RawCell, Object.assign({ className: "faux-cell" }, { children: "State" }), "eft-files-table-header-state"), _jsx(RawCell, Object.assign({ className: "faux-cell" }, { children: "File Date" }), "eft-files-table-header-file-date"), _jsx(RawCell, Object.assign({ className: "faux-cell" }, { children: "Total Amount" }), "eft-files-table-header-total-amount"), _jsx(RawCell, Object.assign({ className: "faux-cell" }, { children: "Last Modified" }), "eft-files-table-header-last-modified"), _jsx(RawCell, Object.assign({ className: "right" }, { children: "Actions" }), "eft-files-header-actions")] }), "eft-files-table-header"), eft_files &&
                            (eft_files === null || eft_files === void 0 ? void 0 : eft_files.map((eft_file) => {
                                var _a;
                                return (_jsxs(BasicRow, { children: [_jsx(TextCell, { children: eft_file === null || eft_file === void 0 ? void 0 : eft_file.file_creation_number }), _jsx(TextCell, { children: _jsx(StatusTag, { status: eft_file === null || eft_file === void 0 ? void 0 : eft_file.status, subtitle: "" }) }), _jsx(TextCell, { children: eft_file === null || eft_file === void 0 ? void 0 : eft_file.file_date }), _jsxs(TextCell, { children: ["$", formatNumber((_a = unitsDividedByMultiplier(eft_file.eftEntriesTotalAmount, 100)) !== null && _a !== void 0 ? _a : 0)] }), _jsx(TextCell, { children: eft_file === null || eft_file === void 0 ? void 0 : eft_file.updated_at }), _jsx(ActionButtonsCell, Object.assign({ className: "nowrap" }, { children: actionButtons(eft_file) }))] }, eft_file.uuid));
                            }))] }), "eft-files-table"), _jsx("div", Object.assign({ className: "pagination-wrap" }, { children: (eft_files === null || eft_files === void 0 ? void 0 : eft_files.length) > 0 && _jsx(KratosPagination, {}) }))] }), "eft-files-landing-landing-header") }));
};
EFTs.layout = (page) => _jsx(Layout, Object.assign({ title: "EFTs" }, { children: page }));
export default EFTs;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { usePage } from "@inertiajs/react";
import logo from "../../../resources/images/logo-morcado.png";
import { Head } from "@inertiajs/react";
import Notification from "../Shared/Notification";
import Loading from "../Shared/Loading";
const Login = () => {
    const { url } = usePage().props;
    const [loading, setLoading] = useState(false);
    function handleSubmit() {
        if (loading === true) {
            return;
        }
        setLoading(true);
        window.location.replace(url);
    }
    return (_jsxs(_Fragment, { children: [_jsx(Notification, { login: true }), _jsx("div", Object.assign({ id: "wrapper" }, { children: _jsxs("div", Object.assign({ id: "login-page" }, { children: [_jsx(Head, { title: "Login" }), _jsxs("div", Object.assign({ className: "login-form" }, { children: [_jsx("img", { src: logo, alt: "Morcado", className: "img-responsive" }), _jsx("button", Object.assign({ type: "button", style: { width: 100, height: 40 }, className: "button button", onClick: handleSubmit }, { children: loading === true ? (_jsx(Loading, { style: { height: 40, marginTop: -13 }, color: "white" })) : ("Log In") }))] }))] })) }))] }));
};
export default Login;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { router, usePage } from "@inertiajs/react";
import { Container, Column, Form, TextComponent, Table, CellHeader, RawCell, TextCell, BasicRow, ActionButton, ActionButtonsCell, TooltipComponent, DateComponent, MoneyComponent, RadioComponent, RadioOption, } from "@truenorthmortgage/olympus";
import { formatNumber, unitsDividedByMultiplier, calcInvestorRate, calcEffectiveInvestorRate, } from "../../../../utils/utils";
import Loading from "../../Loading";
import ProgressBarUnits from "../../ProgressBarUnits";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faTriangleExclamation, } from "@fortawesome/free-solid-svg-icons";
const PurchaseInvestmentForm = ({ callback = null, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    const { CONSTANTS, investment_account, flash, errors } = usePage()
        .props;
    const url = usePage().url;
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [investment_listings, setInvestmentListings] = useState([]);
    const [investment_listing_selected, setInvestmentListingSelected] = useState(null);
    const [purchase_date, setPurchaseDate] = useState(null);
    const [purchase_amount, setPurchaseAmount] = useState(null);
    const [units, setUnits] = useState(null);
    const [purchase_date_error, setPurchaseDateError] = useState((_a = errors === null || errors === void 0 ? void 0 : errors.purchase_date) !== null && _a !== void 0 ? _a : false);
    const [units_error, setUnitsError] = useState((_b = errors === null || errors === void 0 ? void 0 : errors.units) !== null && _b !== void 0 ? _b : false);
    const [purchase_process, setPurchaseProcess] = useState(CONSTANTS.PURCHASE_ORDER.PROCESS.QUEUE.value);
    const [lock_purchase_process, setLockPurchaseProcess] = useState(false);
    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            filterInvestmentListing();
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [search]);
    useEffect(() => {
        var _a, _b;
        setInvestmentListings((_b = (_a = flash === null || flash === void 0 ? void 0 : flash.form) === null || _a === void 0 ? void 0 : _a.investment_listings) !== null && _b !== void 0 ? _b : []);
    }, [(_c = flash === null || flash === void 0 ? void 0 : flash.form) === null || _c === void 0 ? void 0 : _c.investment_listings]);
    useEffect(() => {
        var _a, _b;
        setPurchaseDateError((_a = errors === null || errors === void 0 ? void 0 : errors.purchase_date) !== null && _a !== void 0 ? _a : false);
        setUnitsError((_b = errors === null || errors === void 0 ? void 0 : errors.units) !== null && _b !== void 0 ? _b : false);
    }, [errors]);
    useEffect(() => {
        if ((flash === null || flash === void 0 ? void 0 : flash.success) === "Purchase Order successfully created.") {
            if (callback) {
                callback();
            }
            resetForm();
        }
    }, [flash === null || flash === void 0 ? void 0 : flash.success]);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (((_a = flash === null || flash === void 0 ? void 0 : flash.form) === null || _a === void 0 ? void 0 : _a.balance) &&
            ((_b = investment_account === null || investment_account === void 0 ? void 0 : investment_account.accountingAccount) === null || _b === void 0 ? void 0 : _b.balance) != ((_c = flash === null || flash === void 0 ? void 0 : flash.form) === null || _c === void 0 ? void 0 : _c.balance)) {
            investment_account.accountingAccount.balance = (_d = flash === null || flash === void 0 ? void 0 : flash.form) === null || _d === void 0 ? void 0 : _d.balance;
        }
        if (((_e = flash === null || flash === void 0 ? void 0 : flash.form) === null || _e === void 0 ? void 0 : _e.units_available_to_be_sold) &&
            (investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unitsAvailableToBeSold) !=
                ((_f = flash === null || flash === void 0 ? void 0 : flash.form) === null || _f === void 0 ? void 0 : _f.units_available_to_be_sold)) {
            const investment_listing = investment_listing_selected;
            investment_listing.unitsAvailableToBeSold =
                (_g = flash === null || flash === void 0 ? void 0 : flash.form) === null || _g === void 0 ? void 0 : _g.units_available_to_be_sold;
            setInvestmentListingSelected(investment_listing);
        }
        if (((_h = flash === null || flash === void 0 ? void 0 : flash.form) === null || _h === void 0 ? void 0 : _h.units_pending) &&
            (investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unitsPending) != ((_j = flash === null || flash === void 0 ? void 0 : flash.form) === null || _j === void 0 ? void 0 : _j.units_pending)) {
            const investment_listing = investment_listing_selected;
            investment_listing.unitsPending = (_k = flash === null || flash === void 0 ? void 0 : flash.form) === null || _k === void 0 ? void 0 : _k.units_pending;
            setInvestmentListingSelected(investment_listing);
        }
    }, [
        (_d = flash === null || flash === void 0 ? void 0 : flash.form) === null || _d === void 0 ? void 0 : _d.balance,
        (_e = flash === null || flash === void 0 ? void 0 : flash.form) === null || _e === void 0 ? void 0 : _e.units_available_to_be_sold,
        (_f = flash === null || flash === void 0 ? void 0 : flash.form) === null || _f === void 0 ? void 0 : _f.units_pending,
    ]);
    useEffect(() => {
        if (purchase_date) {
            const chosen_date = new Date(purchase_date).toISOString().split("T")[0];
            const current_date = new Date().toISOString().split("T")[0];
            if (chosen_date < current_date) {
                setPurchaseProcess(CONSTANTS.PURCHASE_ORDER.PROCESS.IMMEDIATELY.value);
                setLockPurchaseProcess(true);
            }
            else {
                setLockPurchaseProcess(false);
            }
        }
    }, [purchase_date, setPurchaseDate]);
    const searchInvestmentsCallback = (value) => {
        setSearch(value);
    };
    const selectInvestmentListingCallback = (investment_listing_object) => {
        setInvestmentListingSelected(investment_listing_object);
        setPurchaseAmount(null);
        setUnits(null);
        setPurchaseDate(null);
    };
    const filterInvestmentListing = () => {
        if ((search === null || search === void 0 ? void 0 : search.length) > 0) {
            router.visit("/investment-listings/modal-search", {
                data: { search_investment_listing: search, url: url },
                only: ["investment_listing"],
                onBefore: () => setLoading(true),
                onFinish: () => {
                    setLoading(false);
                },
                preserveScroll: () => true,
                preserveState: () => true,
            });
        }
        else {
            setLoading(false);
        }
    };
    const purchaseInvestmentCallback = () => {
        router.post("/purchase-order/create", {
            investment_account_uuid: investment_account === null || investment_account === void 0 ? void 0 : investment_account.uuid,
            investment_listing_uuid: investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.uuid,
            units: units,
            purchase_amount: purchase_amount
                ? purchase_amount.toString().replace(/[$,]/g, "")
                : 0,
            purchase_date: purchase_date,
            purchase_process: purchase_process,
        }, {
            onBefore: () => setProcessing(true),
            onFinish: () => {
                setProcessing(false);
            },
            preserveScroll: true,
        });
    };
    const investorRate = (investment_listing_object) => {
        var _a, _b;
        return calcInvestorRate((_a = investment_listing_object === null || investment_listing_object === void 0 ? void 0 : investment_listing_object.investment) === null || _a === void 0 ? void 0 : _a.interest_rate, (_b = investment_listing_object === null || investment_listing_object === void 0 ? void 0 : investment_listing_object.investment) === null || _b === void 0 ? void 0 : _b.servicing_rate);
    };
    const effectiveInvestorRate = (investment_listing_object) => {
        var _a, _b;
        return calcEffectiveInvestorRate("", (_a = investment_listing_object === null || investment_listing_object === void 0 ? void 0 : investment_listing_object.investment) === null || _a === void 0 ? void 0 : _a.interest_rate, (_b = investment_listing_object === null || investment_listing_object === void 0 ? void 0 : investment_listing_object.investment) === null || _b === void 0 ? void 0 : _b.servicing_rate, Number(investment_listing_object === null || investment_listing_object === void 0 ? void 0 : investment_listing_object.return_rate_modifier_bps));
    };
    const purchaseAmountCallback = (value) => {
        var _a;
        setPurchaseAmount(value);
        setUnits(Number(value ? value.toString().replace(/[$,]/g, "") : 0) /
            (investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unit_price));
        if (unitsDividedByMultiplier((_a = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unitsAvailableToBeSold) !== null && _a !== void 0 ? _a : 0, 1) < units) {
            setUnitsError(true);
        }
        else {
            setUnitsError(false);
        }
    };
    const calcCashAvailableAfterPurchase = () => {
        var _a, _b;
        const balance = (_b = unitsDividedByMultiplier((_a = investment_account === null || investment_account === void 0 ? void 0 : investment_account.accountingAccount) === null || _a === void 0 ? void 0 : _a.balance, 100)) !== null && _b !== void 0 ? _b : 0;
        return (balance -
            Number(purchase_amount ? purchase_amount.toString().replace(/[$,]/g, "") : 0));
    };
    const returnCashAvailableAfterPurchase = () => {
        const cash_available_after_purchase = calcCashAvailableAfterPurchase();
        let cash_available_after_purchase_return = "$" + formatNumber(cash_available_after_purchase);
        if (cash_available_after_purchase < 0) {
            cash_available_after_purchase_return =
                "($" + formatNumber(cash_available_after_purchase) + ")";
        }
        return (_jsx("span", Object.assign({ className: cash_available_after_purchase < 0 ? "red" : "" }, { children: cash_available_after_purchase_return })));
    };
    const resetForm = () => {
        setSearch("");
        setLoading(false);
        setProcessing(false);
        setInvestmentListings([]);
        setInvestmentListingSelected(null);
        setPurchaseDate(null);
        setPurchaseAmount(null);
        setPurchaseDateError(false);
        setUnitsError(false);
    };
    return (_jsx(_Fragment, { children: _jsxs(Container, Object.assign({ className: "generic-modal-container" }, { children: [_jsx(Column, Object.assign({ columnStyle: "full" }, { children: _jsx("div", Object.assign({ className: "center" }, { children: _jsx("h2", { children: "Purchase Investment" }) })) })), _jsx(Column, { children: _jsxs(Form, Object.assign({ className: "modal-form-purchase-investment" }, { children: [_jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Purchase from Investment Account" }), _jsx("div", { children: investment_account === null || investment_account === void 0 ? void 0 : investment_account.friendly_id })] })), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Investment Account cash available" }), _jsxs("div", { children: ["$", formatNumber((_h = unitsDividedByMultiplier((_g = investment_account === null || investment_account === void 0 ? void 0 : investment_account.accountingAccount) === null || _g === void 0 ? void 0 : _g.balance, 100)) !== null && _h !== void 0 ? _h : 0)] })] })), investment_listing_selected === null && (_jsxs(_Fragment, { children: [_jsx(Column, Object.assign({ columnStyle: "column full no-padding" }, { children: _jsx(TextComponent, { placeholder: "Enter Investment Listing Friendly ID (e.g. 1234-ABCD-5678) or Borrower's name", id: "search-investment", className: "search_icon margin-bottom", onChange: searchInvestmentsCallback, formData: search }, "search-investment") })), loading === true && _jsx(Loading, { style: { width: "50px" } }), loading !== true &&
                                        ((_j = flash === null || flash === void 0 ? void 0 : flash.form) === null || _j === void 0 ? void 0 : _j.investment_listings) != undefined &&
                                        search !== "" && (_jsx("div", Object.assign({ className: "column full no-padding", style: { marginTop: 5 } }, { children: _jsxs(Table, Object.assign({ showSearchBar: false, title: "", className: "column", emptyText: "No investment listings found", isEmpty: (investment_listings === null || investment_listings === void 0 ? void 0 : investment_listings.length) === 0 }, { children: [_jsxs(CellHeader, Object.assign({ className: "users-header section-header" }, { children: [_jsx(RawCell, { children: "Friendly ID" }), _jsx(RawCell, { children: "Type" }), _jsx(RawCell, { children: "Borrower Last Name" }), _jsx(RawCell, { children: "Units Available" }), _jsx(TextCell, {})] })), investment_listings &&
                                                    (investment_listings === null || investment_listings === void 0 ? void 0 : investment_listings.map((investment_listing) => {
                                                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
                                                        return (_jsxs(BasicRow, { children: [_jsx(TextCell, { children: (_a = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.friendly_id) !== null && _a !== void 0 ? _a : "" }), _jsx(TextCell, { children: (_c = (_b = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _b === void 0 ? void 0 : _b.type_label) !== null && _c !== void 0 ? _c : "" }), _jsx(TextCell, { children: Array.isArray((_d = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _d === void 0 ? void 0 : _d.borrower)
                                                                        ? (_e = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _e === void 0 ? void 0 : _e.borrower[0].lastname
                                                                        : "N/A" }, investment_listing.uuid + "borrowers-name"), _jsxs(TextCell, { children: [formatNumber(unitsDividedByMultiplier((_f = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.unitsAvailableToBeSold) !== null && _f !== void 0 ? _f : 0, (_g = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _g === void 0 ? void 0 : _g.units_multiplier)), _jsx(ProgressBarUnits, { elements: [
                                                                                {
                                                                                    units: unitsDividedByMultiplier((_h = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.units_sold) !== null && _h !== void 0 ? _h : 0, (_j = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _j === void 0 ? void 0 : _j.units_multiplier),
                                                                                },
                                                                                {
                                                                                    units: unitsDividedByMultiplier((_k = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.unitsPendingPurchaseQueueByInvestmentListing) !== null && _k !== void 0 ? _k : 0, (_l = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _l === void 0 ? void 0 : _l.units_multiplier),
                                                                                },
                                                                                {
                                                                                    units: unitsDividedByMultiplier((_m = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.unitsActionQueuePendingPurchaseByInvestmentListing) !== null && _m !== void 0 ? _m : 0, (_o = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _o === void 0 ? void 0 : _o.units_multiplier),
                                                                                },
                                                                                {
                                                                                    units: unitsDividedByMultiplier((_p = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.unitsAvailableToBeSoldConsideringPending) !== null && _p !== void 0 ? _p : 0, (_q = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _q === void 0 ? void 0 : _q.units_multiplier),
                                                                                },
                                                                            ], total: unitsDividedByMultiplier((_r = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.units_listed) !== null && _r !== void 0 ? _r : 0, (_s = investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.investment) === null || _s === void 0 ? void 0 : _s.units_multiplier), show_text: false, show_max: false })] }), _jsx(ActionButtonsCell, { children: _jsx(ActionButton, Object.assign({ id: "select-investment-listing-" +
                                                                            (investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.uuid), title: "Select Investment Listing", callback: () => selectInvestmentListingCallback(investment_listing) }, { children: _jsx(FontAwesomeIcon, { icon: "arrow-right" }) }), "select-investment-listing-" +
                                                                        (investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.uuid)) })] }, investment_listing === null || investment_listing === void 0 ? void 0 : investment_listing.uuid));
                                                    }))] })) })))] })), investment_listing_selected !== null && (_jsxs(_Fragment, { children: [_jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: (_k = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.investment) === null || _k === void 0 ? void 0 : _k.type_label }), _jsx("div", { children: investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.friendly_id })] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12'" }, { children: [_jsxs("div", Object.assign({ className: "wrap grey" }, { children: [_jsx("label", { children: "Investor Rate" }), _jsx(TooltipComponent, Object.assign({ id: "icon", icon: faQuestionCircle, iconClass: "grey" }, { children: _jsx("div", { children: "(investment rate - servicing rate) for this investment" }) }))] })), _jsxs("div", { children: [((_l = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.investment) === null || _l === void 0 ? void 0 : _l.interest_type) ==
                                                        "variable"
                                                        ? "Prime + "
                                                        : "", investorRate(investment_listing_selected), "%"] })] })), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Investment Units" }), _jsx("div", { children: _jsx(ProgressBarUnits, { elements: [
                                                        {
                                                            units: unitsDividedByMultiplier((_m = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.units_sold) !== null && _m !== void 0 ? _m : 0, (_o = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.investment) === null || _o === void 0 ? void 0 : _o.units_multiplier),
                                                            label: "sold",
                                                            show_dollar_sign: true,
                                                            show_text: true,
                                                        },
                                                        {
                                                            units: unitsDividedByMultiplier((_p = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unitsPendingPurchaseQueueByInvestmentListing) !== null && _p !== void 0 ? _p : 0, (_q = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.investment) === null || _q === void 0 ? void 0 : _q.units_multiplier),
                                                            label: "approved purchases pending processing",
                                                            show_dollar_sign: true,
                                                            show_text: ((_r = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unitsPendingPurchaseQueueByInvestmentListing) !== null && _r !== void 0 ? _r : 0) > 0,
                                                        },
                                                        {
                                                            units: unitsDividedByMultiplier((_s = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unitsActionQueuePendingPurchaseByInvestmentListing) !== null && _s !== void 0 ? _s : 0, (_t = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.investment) === null || _t === void 0 ? void 0 : _t.units_multiplier),
                                                            label: "pending purchase requests",
                                                            show_dollar_sign: true,
                                                            show_text: ((_u = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unitsActionQueuePendingPurchaseByInvestmentListing) !== null && _u !== void 0 ? _u : 0) > 0,
                                                            link: {
                                                                url: "/action-queues",
                                                                title: "View Action Queue",
                                                            },
                                                        },
                                                        {
                                                            units: unitsDividedByMultiplier((_v = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unitsAvailableToBeSoldConsideringPending) !== null && _v !== void 0 ? _v : 0, (_w = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.investment) === null || _w === void 0 ? void 0 : _w.units_multiplier),
                                                            label: "safe to sell",
                                                            show_dollar_sign: true,
                                                            show_text: true,
                                                        },
                                                    ], total: unitsDividedByMultiplier((_x = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.units_listed) !== null && _x !== void 0 ? _x : 0, (_y = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.investment) === null || _y === void 0 ? void 0 : _y.units_multiplier) }) })] })), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsxs("div", { children: [_jsx("label", { children: "Return Rate Modifier" }), _jsxs("div", { children: [investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.return_rate_modifier_bps, "%"] })] }), _jsxs("div", Object.assign({ style: { marginTop: 20 } }, { children: [_jsxs("div", Object.assign({ className: "wrap grey" }, { children: [_jsx("label", { children: "Effective Investor Rate" }), _jsx(TooltipComponent, Object.assign({ id: "icon", icon: faQuestionCircle, iconClass: "grey" }, { children: _jsx("div", { children: "(investor rate + return rate modifier)" }) }))] })), Number(effectiveInvestorRate(investment_listing_selected)) > Number(investorRate(investment_listing_selected)) ? (_jsxs("div", Object.assign({ className: "wrap red" }, { children: [_jsxs("div", Object.assign({ className: "warning" }, { children: [((_z = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.investment) === null || _z === void 0 ? void 0 : _z.interest_type) == "variable"
                                                                        ? "Prime + "
                                                                        : "", effectiveInvestorRate(investment_listing_selected), "%"] })), _jsx(TooltipComponent, Object.assign({ id: "icon", icon: faTriangleExclamation, iconClass: "red" }, { children: _jsx("div", { children: "This listing offers this investment at a loss to the Issuing Investor." }) }))] }))) : (_jsxs("div", { children: [((_0 = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.investment) === null || _0 === void 0 ? void 0 : _0.interest_type) == "variable"
                                                                ? "Prime + "
                                                                : "", effectiveInvestorRate(investment_listing_selected), "%"] }))] }))] })), _jsxs("div", Object.assign({ className: "side-by-side-element column col-12-12", style: { paddingLeft: 0 } }, { children: [_jsx(DateComponent, { id: "purchase-date", dateFormat: "Y-m-d", onChange: setPurchaseDate, label: "Purchase Date", placeholder: "Click to set a date", error: purchase_date_error }, "purchase-date"), _jsx("div", { children: lock_purchase_process === false ? (_jsxs(RadioComponent, Object.assign({ value: purchase_process, onChange: setPurchaseProcess, name: "purchase_process" }, { children: [_jsx(RadioOption, { label: "Add to purchase queue", value: CONSTANTS.PURCHASE_ORDER.PROCESS.QUEUE.value }, "option-1"), _jsx(RadioOption, { label: "Process immediately", value: CONSTANTS.PURCHASE_ORDER.PROCESS.IMMEDIATELY.value }, "option-2")] }))) : (_jsx(RadioComponent, Object.assign({ value: CONSTANTS.PURCHASE_ORDER.PROCESS.IMMEDIATELY.value, onChange: setPurchaseProcess, name: "purchase_process" }, { children: _jsx(RadioOption, { label: "Process immediately", value: CONSTANTS.PURCHASE_ORDER.PROCESS.IMMEDIATELY.value }, "option-2") }))) })] })), _jsx(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: purchase_process ===
                                            CONSTANTS.PURCHASE_ORDER.PROCESS.IMMEDIATELY.value &&
                                            ((investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unitsActionQueuePendingPurchaseByInvestmentListing) >
                                                0 ||
                                                (investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unitsPendingPurchaseQueueByInvestmentListing) >
                                                    0) && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "red bold" }, { children: "Warning" })), _jsxs("div", Object.assign({ className: "wrap red", style: { marginTop: 10, textAlign: "justify" } }, { children: [_jsx("i", { className: "fa-solid fa-triangle-exclamation", style: { marginRight: 10 } }), "There are purchases pending processing in the system. Processing this purchase immediately may affect those pending processing."] }))] })) })), _jsx(MoneyComponent, { id: "purchase_amount", label: "Purchase Amount", columnStyle: "side-by-side-element column col-12-12", error: units_error, value: purchase_amount, onChange: purchaseAmountCallback }, "purchase_amount"), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Cash available after purchase" }), _jsx("div", { children: returnCashAvailableAfterPurchase() })] })), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Total Units" }), _jsx("div", { children: _jsx("span", Object.assign({ className: unitsDividedByMultiplier((_1 = investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unitsAvailableToBeSold) !== null && _1 !== void 0 ? _1 : 0, 1) < units
                                                        ? "red"
                                                        : "" }, { children: formatNumber(units) })) })] })), _jsxs(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: [_jsx("label", { children: "Unit Price" }), _jsxs("div", { children: ["$", formatNumber(investment_listing_selected === null || investment_listing_selected === void 0 ? void 0 : investment_listing_selected.unit_price)] })] })), _jsx(Column, Object.assign({ columnStyle: "side-by-side-element" }, { children: _jsxs("button", Object.assign({ type: "button", className: "button", style: { marginTop: 20 }, onClick: () => setInvestmentListingSelected(null) }, { children: [_jsx(FontAwesomeIcon, { icon: "arrow-left", style: { marginRight: 5 } }), "Back"] }), "purchase-investment-back") })), _jsx(Column, Object.assign({ columnStyle: "side-by-side-element right" }, { children: _jsxs("button", Object.assign({ type: "button", className: "button submit-button green", onClick: purchaseInvestmentCallback, disabled: processing }, { children: [_jsx(FontAwesomeIcon, { icon: "dollar" }), "Record Purchase"] }), "purchase-investment-button") }))] }))] })) })] })) }));
};
export default PurchaseInvestmentForm;
